@media (min-width: 1300px) {
	[data-rbd-droppable-id='board-droppable'] {
		width: 100% !important;
	}
	.react-kanban-column {
		width: calc(33.3333333% - 12px) !important;

		& > div {
			width: 100%;
			& > div {
				width: 100%;
				& > div {
					width: 100%;
				}
			}
		}

		.borad-width {
			width: 100% !important;
		}
	}
}
@media (min-width: 1025px) and (max-width: 1299px) {
	.borad-width {
		min-width: 300px;
	}
}
@media (max-width: 1024px) {
	.borad-width {
		width: 290px !important;
		min-width: inherit;
	}
}

.react-kanban-board {
	width: auto;
	overflow: visible !important;
	font-size: 12px;
	width: 100%;
	> div {
		width: 100% !important;

		.react-kanban-column {
			margin-bottom: 30px;
			width: calc((100% - (5px * (4 * 2))) / 4) !important;
		}
	}

	.card {
		margin-bottom: 0 !important;
	}
}

.progress-container {
	width: 100%;
	background-color: #1a5fb4;
	animation: increaseWidth 3s ease;
}

.progress-bar {
	width: 100%;
	height: 3px;
}

.progress-label {
	text-align: center;
	line-height: 30px;
	color: #fff;
}

@keyframes increaseWidth {
	from {
		width: 0;
	}
}

// @media (max-width: 1024px) {
// 	.borad-width {
// 		min-width: 270px;
// 	}
// }

// .task-box.card {
// 	margin-bottom: 0px !important;
// }

// .react-kanban-column {
// 	width: 33.3333333%;

// 	& > div:nth-child(2) {
// 		width: 100% !important;
// 		& > div {
// 			width: 100% !important;

// 			& > div {
// 				width: 100%;
// 				display: block;
// 			}
// 		}
// 	}
// }

// @media (max-width: 992px) {
// 	.react-kanban-column {
// 		width: 300px;
// 	}
// }

// [data-rbd-droppable-id="board-droppable"] {
// 	width: 100%;
// }
.dt-datepicker {
	width: 100%;
	height: 38px;
	border-radius: 3px;
	border: 1px solid hsl(0, 0%, 80%);
	padding: 0 10px;
	color: rgb(51, 51, 51);
}
