/* src/Certificate.css */

#certificate {
    font-family: 'Arial, sans-serif';
    text-align: center;
    border: 2px solid #000;
    padding: 40px;
    margin: 20px auto;
    width: 80%;
    max-width: 600px;
  }
  
  #certificate h1 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  #certificate h2 {
    font-size: 22px;
    margin: 10px 0;
  }
  
  #certificate h3 {
    font-size: 20px;
    margin: 10px 0;
  }
  
  #certificate p {
    font-size: 16px;
    margin: 5px 0;
  }
  
  .downloadButton {
    display: block;
    margin: 20px auto;
    padding: 10px 20px;
    font-size: 16px;
  }

  .certificateContainer {
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
  }
  