html[data-module='gd']{
.copyURl {
  width: 584px;
}

@media (max-width: 780px) {
  .copyURl {
    width: 100%; /* or any other width you prefer for smaller screens */
  }
}

.settings{
.restoreBtn {
  border: 1px solid #5664d2;
  background: #fff;
  border-radius: 8px;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  color:#5664D2;
  padding: 6px 14px;
}

.saveBtn {
    background: #414FBD;
    border-radius: 8px;
    font-size: 12px;
    font-weight: 600;
    text-align: center;
    color:#fff;
    padding: 6px 14px;
    border:unset;
  }

  .accordionBody{
    box-shadow: 0px 4px 4px 0px #00000040;
    background: #F5F6FF;
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 18px;

    .accordion-button{
        background: transparent;
        box-shadow: none;
        color:#000;
        font-size: 16px;
        font-weight: 600;
    }
    .accordion-body{
        margin-top: 18px;
    }

  }
}
}