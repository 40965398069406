.profile-page{
    .account-avatar-updator {
        display: flex;
        justify-content: left;
        align-items: center;

        .avatar-preview {
            margin-right: 22px;
            border: 2px solid #828282;
            border-radius: 100%;
            overflow: hidden;
            width: 160px;
            height: 160px;
            display: flex;
            justify-content: center;
            align-items: center;

            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            span{
                text-align: center;
            }
        }

        .actions-container {
            .user-fullname{
                font-weight: 700;
                color: #4F4F4F;
                font-size: 18px;
            }

            .actions{
                margin-top: 12px;

                .change-avatar-btn{
                    position: relative;
                    input[type='file']{
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        left: 0px;
                        top: 0px;
                        opacity: 0;
                    }
                }
            }
        }
    }

    .notifications-settings{
        .settings-title{
            color: #282828;
            font-size: 16px;
            font-weight: 600;
        }

        .notifications-settings-group{
            border: 1px solid #828282;
            border-radius: 4px;
            padding: 8px;

            .group-title{
                font-size: 16px;
                color: #282828;
                font-weight: 400;
            }

            .loading-item {
                display: flex;
                justify-content: start;
                align-items: center;
                margin-bottom: 8px;

                > div:first-child {
                    width: 40px;
                    margin-right: 8px;
                }

                > div:last-child {
                    width: 80%;
                }
            }
        }
    }
}