.react-tel-input{
    font-size: font-sizes(common, large);
    font-weight: 400;

    input[type=tel]{
        padding: 4px 0px 4px 52px;
        border-color: color(black, 4);
        border-radius: 4px;
        font-size: font-sizes(common, large);
        font-weight: 400;
    }

    .flag-dropdown{
        border-color: color(black, 4);
        width: 48px;
    }
}

.read-only-phone-input{
    .react-tel-input{
        input[type=tel]{
            padding: 0px;
            border:none;
            height: auto !important;
            line-height: 0;
            font-size: font-sizes(common, medium);
        }

        &.background-transparent{
            input[type=tel]{
                background: transparent;
            }
        }

        .flag-dropdown{
            display: none;
        }
    }
}