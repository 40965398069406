.table-container {
    width: 100%;
    margin: auto;
  
    table {
      width: 100%;
      border-collapse: separate; /* Disable collapsing borders */
      border-spacing: 0; /* Remove spacing between cells */
      border: 1px rgba(137, 155, 161, 1) solid;
      border-radius: 12px;
      margin-bottom: 20px;
  
      th:first-child, td:first-child {
        border-top-left-radius: 12px; /* Round top-left of the first cell in each row */
        border-bottom-left-radius: 12px; /* Round bottom-left of the first cell in each row */
      }
  
      th:last-child, td:last-child {
        border-top-right-radius: 12px; /* Round top-right of the last cell in each row */
        border-bottom-right-radius: 12px; /* Round bottom-right of the last cell in each row */
      }
  
      th, td {
        text-align: left;
        padding: 8px;
      }
    }
  
    .edit-btn, .delete-btn {
      margin-right: 10px;
      cursor: pointer;
    }
}

.third-party-home-page {
    padding: 56px 0px;

    .third-party-header{
        width: 100%;
        aspect-ratio: 16 / 5;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        min-height: 200px;
        max-height: 375px;
        border-radius: 8px;
        box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    }

    .background-image-container{
        width: 100%;
        margin: 0px;
        position: relative;
        top: -40px;

        img{
            width: 100%;
            max-width: 100%;
            max-height: 100%;
        }
    }

    .actions {
        display: flex;
        justify-content: center;
        align-items: center;

        .action-button-container {
            background-color: #fff;
            border: 1px solid #ddd;
            border-radius: 8px;
            padding: 20px;
            text-align: center;
            width: 212px;
            margin: 8px 10px;
            transition: transform 0.3s ease, box-shadow 0.3s ease;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            &:hover{
                transform: translateY(-5px); 
                box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
            }
            &.active{
                transform: translateY(-5px);
                box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
            }

            .quick-access-box-icon {
                font-size: 64px;
                color: var(--color-primary);
            }
            
            .quick-access-box-text {
                color: #333;
                font-weight: bold;
            }

            .badge {
                position: absolute;
                top: 10px;
                right: 10px;
                background-color: #ff5733;
                color: white;
                font-size: 12px;
                padding: 5px 10px;
                border-radius: 15px;
            }
        }
    }
}

@media only screen and (max-width: 1024px) {
    .third-party-home-page{
        margin-top: 0px;
        padding: 0px;
        .third-party-header{
            border: none;
            border-radius: 0px;
        }

        .actions{
            .action-button-container {
                width: 120px;
                height: 140px;
                padding: 4px;
                .quick-access-box-icon {
                    font-size: 48px;
                }
            }
        }
    }
}