.btn{
    &.tpdd-btn{
        padding         :   $btn-custom-padding;
        border-radius   :   $btn-custom-radius;
        font-weight     :   $btn-custom-font-weight;
        font-size       :   $btn-custom-font-size;
        border          :   none;
        line-height     :   20px;
        min-width       :   145px;
        &:disabled{
            background-color    :   #828282 !important;
            opacity             :   1;
        }
    }
}
