.children-questions-container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    flex-direction: row;
    margin-top: 12px;
    padding: 0px 0px;

    .children-question {
        display: flex;
        flex-direction: column;
        flex-basis: 50% !important;
        flex: 2;
        padding: 0px 22px;
    }
}