.subItem {
  font-family: Inter;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(79, 79, 79, 1);
}

.custom--processs-directory-modal-header{
  border-bottom: none !important;
  padding-right: 2rem !important;
  padding-left: 2rem !important;
  padding-top: 2rem !important;

}

.custom--processs-directory-modal-header .close{
  top: 1rem !important;
  right: 0.5rem !important;
  opacity: 1 !important;
}