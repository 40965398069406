.custom-table-filter{
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    .filter-badge {
        position: absolute;
        top: -10px;
        left: -10px;
        background-color: rgba(255, 165, 0, 1);
        color: white;
        font-size: 0.8rem;
        font-weight: bold;
        padding: 0.4rem;
        border-radius: 50%; /* Makes it circular */
        width: 1.5rem;
        height: 1.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
        z-index: 1;
    }

    .dropdown-menu{
        left: auto !important;
        right: 0px !important;
        padding-bottom: 0px;
    }

    .dropdown{
        > button{
            width: 300px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            span{
                display: flex;
                flex: auto;
                margin-left: 6px;
            }
        }

        .filters-container{
            padding: 6px 8px 0px 8px;

            .nav{
                .nav-item{
                    .nav-link{
                        border: none;
                        border-bottom: 3px solid transparent;
                        display: flex;
                        justify-content: space-evenly;
                        align-items: center;
                        flex-flow: column;
                        height: 86px;

                        > i {
                            font-size: 24px;
                        }

                        img{
                            max-width: 20px;
                        }
                    }

                    &.active{
                        .nav-link{
                            color: #5664D2;
                            border-bottom: 3px solid;
                            border-bottom-color: #045CFF;
                        }
                    }
                }
            }

            .navs-content-container{
                padding: 8px 0px;
                overflow: auto;
                max-height: 382px;

                .filter-items-container{
                    max-height: 320px;
                    min-width: 396px;
                    overflow: auto;
                    .filter-item {
                        margin: 0px;
                        padding: 8px 16px 8px 16px;
                    }
                }
            }

            
        }

        .actions-container{
            padding: 8px 12px 8px 12px;
            border-top: 1px solid #00000020;
        }
    }

    .reset-btn{
        position: relative;
        &::before{
            content: '';
            position: absolute;
            top: 0px;
            right: 0px;
            height: 100%;
            width: 1px;
            background-color: #000;
        }
    }
}