.custom-table {
	display: flex;
	flex-direction: column;

	.table-header-container {
		height: 53px;
		color: #8a8da3;
		border-bottom: 1px solid #f5f4f7;
	}

	.table-header-item {
		flex-grow: 1;
		padding: 16px 10px;
	}

	.row-container {
		display: flex;
		flex-direction: row;
		height: 56px;
		color: #1a2052;
		transition: background-color 0.1s ease-out;
	}

	.row-container:hover {
		background-color: #f5f5f5 !important;
	}

	.item-container {
		padding: 16px 10px;
	}
}
