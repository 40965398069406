html[data-module='gd']{
.custom-pagination {
	width: 100%;
	display: flex;
	flex-direction: row-reverse;
	align-items: center;
	justify-content: space-between;
	padding: 40px 30px 20px;
	.custom-pagination-per-page {
		.custom-pagination-per-page-text {
			margin-bottom: 0;
			font-weight: 600;
			color: #1a2052;
		}
	}
	.custom-pagination-items {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		gap: 10px;
		.custom-pagination-items-total-page {
			margin-bottom: 0;
			font-weight: 600;
			color: #1a2052;
		}
		.custom-pagination-items-number-button,
		.custom-pagination-items-button {
			background: #f5f4f7;
			color: #1a2052;
			border-radius: 8px;
			min-width: 90px;
			height: 44px;
			display: flex;
			align-items: center;
			justify-content: center;
			font-weight: 600;
			transition: background ease 0.2s, color ease 0.2s, opacity ease 0.2s;
			&.custom-pagination-items-button-disabled {
				cursor: not-allowed;
				opacity: 0.7;
			}
		}
		.custom-pagination-items-number-button {
			min-width: 44px !important;
			padding-bottom: 3px;
			&.custom-pagination-items-number-button-disabled {
				cursor: not-allowed;
				opacity: 0.7;
			}
		}

		.custom-pagination-items-number-button.custom-pagination-items-number-button-active,
		.custom-pagination-items-number-button:hover:not(.custom-pagination-items-number-button-disabled),
		.custom-pagination-items-button:hover:not(.custom-pagination-items-button-disabled) {
			background: #1a2052;
			color: #ffffff;
		}
	}
}
}