._loading_overlay_wrapper{
    ._loading_overlay_overlay{
        bottom: 38px;
        top: auto;
        margin: 0px;
        background: transparent;

        ._loading_overlay_content{
            display: flex;
            justify-content: center;
            align-items: center;
            flex-flow: column;
            font-size: 12px;
            font-weight: 600;
            margin-top: 16px;
            color: #1A1D30;
        }
    }
}

.tpdd-table{
    thead{
        th{
            font-size: font-sizes(common, small);
            color: color(grey, 2);
            font-weight: 600;
        }
    }

    td{
        font-size: font-sizes(common, small);
        color: #1A1D30;
        font-weight: 400;
        // border: none;

        .actions{
            display: flex;
            justify-content: start;
            .btn{
                font-size: 22px;
                font-weight: 400;
            }
        }
    }
}