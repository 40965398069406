.settings{
  .accordionBody{
    box-shadow: 0px 4px 4px 0px #00000040;
    background: #F5F6FF;
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 18px;

    .accordion-button{
        background: transparent;
        box-shadow: none;
        color:#000;
        font-size: 16px;
        font-weight: 600;
    }
    .accordion-body{
        margin-top: 18px;
    }
  }

  .notification-loading-item {
        display: flex;
        justify-content: start;
        align-items: center;
        margin-bottom: 8px;

        > div:first-child {
            width: 40px;
            margin-right: 8px;
        }

        > div:last-child {
            width: 80%;
        }
    }
}