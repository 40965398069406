//
// components-rtl.scss
//

// UI Elements

.ui-elements-demo-bar{
  margin-left: 0;
  margin-right: 20px;
}

// 

.search-box{
  .form-control{
    padding-right: 40px;
    padding-left: 20px;
  }

  .search-icon{
    left: auto;
    right: 13px;
  }
}

// dropdown

.dropdown-megamenu{
  &.show{
    left: 20px!important;
  }
}


// icons

.icon-demo-content{
  i{
    margin-left: 10px;
    margin-right: 0;
  }
}


// Breadcrumb

.breadcrumb-item + .breadcrumb-item::before {
  float: right;
  padding-left: 0.5rem;
  padding-right: 0;
}

// activity-feed

.activity-feed{
  .feed-item {
    padding-left: 0;
    padding-right: 16px;
    border-left: 0;
    border-right: 2px solid $light;
    &:after {
      left: auto;
      right: -6px;
    }
  }
}


// Invoice

@media print {
  .content-page,
  .content,
  body {
      margin-right: 0;
  }
}

// Demos button 
.demos-show-btn {
  left: 0;
  right: auto;
  border-radius: 0 6px 6px 0;
}

