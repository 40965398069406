.eye-password-field  {
    position: relative;

    input.was-validated .form-control:invalid,
    input.form-control.is-invalid{
        background-image: none !important;
    }

    .toggle-field-type {
        border: none;
        padding: 0px;
        margin: 0px;
        background: transparent;
        position: absolute;
        right: 8px;
        top: 0px;
        font-size: 24px;
        display: inline;
        height: auto;
        height: 100%;
        line-height: 2;
    }
}