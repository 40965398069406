.toggle-switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 28px;
}
  
.toggle-switch input[type="checkbox"] {
    display: none;
}

.toggle-switch .switch {
    position: absolute;
    cursor: pointer;
    background-color: #aaa;
    border-radius: 6px;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: background-color 0.2s ease;
}

.toggle-switch .switch::before {
    position: absolute;
    content: "AI";
    left: -18px;
    top: -6px;
    width: 40px;
    height: 40px;
    background-color: #a1a1a1;
    border-radius: 50%;
    transition: transform 0.3s ease;
    border: 1px solid #545b9a;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
}

.toggle-switch input[type="checkbox"]:checked + .switch::before {
    transform: translateX(50px);
    background: #899ba1;
}

.toggle-switch input[type="checkbox"]:checked + .switch {
    background-color: #545b9a;
}