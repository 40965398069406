.playerContainer {
  height: 90vh;
  position: relative;
  overflow: scroll;
}

.choice-view__choice-container {
  width: auto;
  height: auto !important;
  background-color: red !important;
}

.player {
  position: absolute;
  top: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
  margin-top: 2% !important;
  padding: 0 !important;
  display: flex;
  align-items: center;
  justify-content: flex;
}

/* .universal {
  top: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
  margin: 0 !important;
  padding: 0 !important;
  width: 100% !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center;
}

.modal-layer {
    background-color: #ffffff !important;
    opacity: 0 !important;
}

.message-box {
    background-color: #000000 !important;
    opacity: 0.4 !important;
} */