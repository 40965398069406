.history-logs-details-page {
	.card-body {
		> * {
			margin-bottom: 12px;
		}
	}

	p {
		margin-bottom: 0;
	}
	.title-left {
		color: #8a8da3;
		font-weight: 700;
		font-size: 14px;
	}

	.title-right {
		color: #1a2052;
		font-weight: 700;
		font-size: 14px;
	}

	.description {
		color: #1a2052;
		font-size: 14px;
		display: inline;
	}

	.icon-style {
		width: 24px;
		height: 24px;
	}

	.color-red {
		color: #fc5959;
	}

	.back-button {
		display: block;
		margin-bottom: 14px;
		margin-left: 6px !important;
		background-color: #5664d2 !important;
		width: 192px;
		height: 48px;
		border-radius: 8px !important;
		border: none !important;
		display: flex;
		align-items: center;
		justify-content: center;
		color: #ffffff;
		transition: opacity ease 0.3s;
		font-weight: 500;
		&:hover {
			opacity: 0.8;
		}
		img {
			margin-right: 8px;
			display: inline-block;
		}
	}
}
