.thirdparty-questionnaire{
    .declaration-confirm-form{
        .card-title,
        .card-body,
        .form-check label{
            font-size: 16px;
            color: #4F4F4F;
            font-weight: 400;
        }
    
        .form-check label{
            color: #000;
        }
    }
}