$heading-font-sizes  :   (
    h1  :   40px,
    h2  :   24px,
    h3  :   18px
);

$sub-heading-font-sizes  :   (
    small       :   18px,
    medium      :   20px,
    large       :   22px
);

$caption-font-sizes   :   (
    base    :   12px
);

$footer-font-sizes   :   (
    base    :   10px
);

$common-font-sizes   :   (
    base        :   14px,
    small       :   12px,
    medium      :   14px,
    large       :   16px,
    extra-large :   18px
);

$size-palettes   :   (
    heading     :   $heading-font-sizes,
    sub-heading :   $sub-heading-font-sizes,
    common      :   $common-font-sizes,
    caption     :   $caption-font-sizes,
    footer      :   $footer-font-sizes
);

@function font-sizes($palatte, $tone){
    @if map-has-key($size-palettes, $palatte){
        $palatte: map-get($size-palettes, $palatte);

            @if map-has-key($palatte, $tone){
                $tone: map-get($palatte, $tone);
                @return $tone;
            }

            @warn "unknown tone `#{$tone}` in size";
            @return null;        
    }

    @warn "unknown size `#{$palatte}` in palette";
    @return null;
}