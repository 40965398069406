.custom-upload-file-input {
    position: relative;
    width: 100%;
    height: 50px;

    .mask {
        z-index: 1;
        background: transparent;
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0px;
        top: 0px;
        display: flex;
        justify-content: start;
        align-items: center;
        border: 1px solid #ced4da;
        border-radius: 0.25rem;

        .title{
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #ececec;
            border-right: 1px solid #ced4da;
            padding: 0px 8px;
        }

        .file-name{
            padding: 0px 8px;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    > .form-group {
        position: absolute;
        z-index: 2;
        width: 100%;
        height: 100%;
        left: 0px;
        top: 0px;
        opacity: 0;
    }
}