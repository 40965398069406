.new-thirdparty-page{
  .table-container {
    width: 100%;
    margin: auto;
    border-radius: 8px;
    margin-bottom: 20px;

    table {
      width: 100%;
      border-collapse: separate; /* Disable collapsing borders */
      border-spacing: 0; /* Remove spacing between cells */
      border-radius: 8px;
      padding: 2px 0px;

      thead{
        th, 
        td{
            font-size: 16px;
            font-weight: 600;
            color: color(grey, 2);
        }

        td{
            font-weight: 400;
        }
      }

      tbody{
        td:last-child {
          font-size: 21px;
        }
      }
      

      th, td {
        text-align: left;
        padding: 8px;

        p{
            margin-bottom: 0px;
        }
      }
    }

    .edit-btn, .delete-btn {
      margin-right: 10px;
      cursor: pointer;
    }
  }

.confirm-form-sections-container{
    box-shadow: 0px 4px 4px 0px #00000040;
    border-radius: 12px;
    border: none;
    background-color: #fff;
    padding: 16px;
    color: #000000;
    margin-bottom: 1.5rem;

    .section{
        border: 1px solid #828282;
        padding: 16px;
        border-radius: 8px;
        margin-bottom: 16px;

        .section-header{
            span{
                font-weight: 600;
                font-size: 18px !important;
            }
            .edit-section-btn{
                border: none;
                font-size: 22px;
                color : color(black, 1);
            }
        }
        .section-body{
            padding: 8px 32px 8px 32px !important;
            p{
                font-size: 14px;
                font-weight: 400;
            }
        }
    }
}

  .actions-buttons-container{
    .btn{
      min-width: 195px;
    }
  }
}