/* Default styles for mobile view */
.react-player {
    width: 100%;
}

/* Media query for laptop mode (assuming breakpoint is 768px) */
@media screen and (min-width: 768px) {
    .react-player {
        width: 60%;
    }
}
