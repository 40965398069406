// .PhoneInputInput {
// 	width: 100%;
// 	display: block;
// 	padding: 0.47rem 0.75rem;
// 	font-size: 0.9rem;
// 	font-weight: 400;
// 	line-height: 1.5;
// 	color: #505d69;
// 	background-color: #fff;
// 	background-clip: padding-box;
// 	border: 1px solid #ced4da;
// 	appearance: none;
// 	border-radius: 0.25rem;
// 	transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
// }
// .PhoneInputCountry {
// 	min-width: 40px;
// }
.sip-phone-input {
	input.form-control {
		width: 100%;
        height: 38.61px;
	}
}
