@keyframes newNotificationAnimation {
	0% {
		opacity: 0;
		max-height: 0px;
	}
	50% {
		opacity: 0;
		max-height: 1000px;
	}
	100% {
		opacity: 1;
		max-height: 1000px;
	}
}
