.twitter-bs-wizard {
    .twitter-bs-wizard-nav {
        position: relative;
        display: flex;
        justify-content: space-between;
        white-space: nowrap;

        &:before {
            content: "";
            width: 100%;
            height: 2px;
            background-color: $gray-300;
            position: absolute;
            left: 0;
            top: 26px;
        }

        .step-number {
            display: inline-block;
            width: 38px;
            height: 38px;
            line-height: 34px;
            border: 2px solid $primary;
            color: $primary;
            text-align: center;
            border-radius: 50%;
            position: relative;
            background-color: $card-bg;

            @media (max-width: 991.98px) {
                display: block;
                margin: 0 auto 8px !important;
            }
        }

        .nav-link {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 0 10px;

            .step-title {
                display: block;
                margin-top: 8px;
                font-weight: $font-weight-bold;

                @media (max-width: 575.98px) {
                    display: none;
                }
            }

            &.active {
                background-color: transparent;
                color: $gray-700;

                .step-number {
                    background-color: $primary;
                    color: $white;
                }
            }
        }

        @media (max-width: 767.98px) {
            flex-wrap: nowrap;
            justify-content: flex-start;
            overflow-x: auto;
            white-space: nowrap;

            &:before {
                display: none;
            }

            .nav-item {
                flex: 0 0 auto;
                margin-bottom: 10px;
                width: auto;

                .nav-link {
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    .step-number {
                        font-size: 12px;
                    }

                    .step-title {
                        font-size: 12px;
                        display: block;
                        margin-top: 5px;
                    }
                }

                &:before {
                    display: none;
                }

                &.completed .nav-link .step-number::before {
                    border-color: #5664D2;
                }
            }
        }
    }

    .twitter-bs-wizard-pager-link {
        padding-top: 24px;
        padding-left: 0;
        list-style: none;
        margin-bottom: 0;

        li {
            display: inline-block;

            a {
                display: inline-block;
                color: $white;
            }

            &.disabled a {
                cursor: not-allowed;
                background-color: lighten($primary, 8%);
            }

            &.next {
                float: right;
            }
        }
    }
}

.twitter-bs-wizard-tab-content {
    padding-top: 24px;
    min-height: 262px;
}
