html[data-module='gd']{
.data-protection-software-list-page {
	min-height: 650px;

	.priority-icon,
	.flag-icon {
		width: 24px;
		height: 24px;
	}

	.priority {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		.priority-icon {
			margin-right: 4px;
		}
	}

	.status {
		padding: 0 10px;
		width: auto;
		display: inline-block;
		border-radius: 6px;
		line-height: 34px;
		min-width: 100%;
		font-weight: 600;
		text-align: center;
	}
	
	.remove{
		cursor: pointer;
	}
}
}