.btn{
    &:not(.bootstrap-default){
        padding: 5px 16px 5px 16px;
        
        /*
            default is btn-md styles
        */
        font-size: font-sizes(common, medium);
        font-weight: 700;
        /**------------------------*/

        &.btn-primary{
            background-color: color(primary, 500);
            color: color(white, 1);

            &:hover{
                background-color: color(primary, 700);
            }

            &:disabled{
                background-color: color(grey, 3);
                border-color: color(grey, 3);
            }
        }

        &.btn-outline-primary{
            border-color: color(primary, 500);
            background-color: transparent;
            color: color(primary, 500);

            &:hover{
                background-color: color(primary, 100);
            }

            &:disabled{
                border-color: color(grey, 3);
                background-color: color(white, 1);
                color: color(grey, 3);
            }
        }

        &.btn-sm{
            font-size: font-sizes(common, small);
            font-weight: 700;
        }

        &.btn-md{
            font-size: font-sizes(common, medium);
            font-weight: 700;
        }

        &.btn-lg{
            font-size: font-sizes(common, large);
            font-weight: 600;
        }
    }
}