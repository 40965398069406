.accordion_progress {
  width: 100%;
  display: flex;
  justify-content: space-between;
  .inner_progress {
    width: 50%;
    display: flex;
    justify-content: flex-end;
  }
  .progress_label {
    margin: 0px 20px;
  }
}


.bg-white-accordion .accordion-button:not(.collapsed) {
  background-color: #fff !important;
  color: $font-color-black;
}
