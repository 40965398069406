// 
// Chat.scss
//

.chat-leftsidebar {
    background-color: $card-bg;
    border-radius: $border-radius 0 0 $border-radius;
    box-shadow: $box-shadow;

    @media (min-width: 992px) {
        min-width: 360px;
    }


    .chat-leftsidebar-nav {
        .nav {
            background-color: $gray-300;

            .nav-link {
                &.active {
                    background-color: $card-bg;
                    color: $primary;
                }
            }
        }

    }
}

.chat-noti-dropdown {
    &.active {
        &:before {
            content: "";
            position: absolute;
            width: 8px;
            height: 8px;
            background-color: $danger;
            border-radius: 50%;
            right: 0;
        }
    }

    .btn {
        padding: 0px;
        box-shadow: none;
        font-size: 16px;
    }
}

.chat-search-box {
    .form-control {
        border: 0;
    }
}

.chat-list {
    margin: 0;

    li {
        &.active {
            a {
                background-color: rgba($gray-300, 0.7);
            }
        }

        a {
            display: block;
            padding: 14px 16px;
            color: $gray-600;
            transition: all 0.4s;
            border-top: 1px solid $border-color;
            border-radius: 4px;

            &:hover {
                background-color: rgba($gray-300, 0.7);
            }
        }

        .user-img {
            position: relative;

            .user-status {
                width: 10px;
                height: 10px;
                background-color: $gray-500;
                border-radius: 50%;
                border: 2px solid $card-bg;
                position: absolute;
                right: 0;
                bottom: 0;


            }

            &.online {
                .user-status {
                    background-color: $success;
                }
            }

            &.away {
                .user-status {
                    background-color: $warning;
                }
            }
        }
    }
}

.user-chat {
    background-color: $card-bg;
    box-shadow: $box-shadow;
    .user-chat-border {
        border-bottom: 1px solid $border-color;
    }
}

.user-chat-nav {
    .dropdown {
        .nav-btn {
            height: 36px;
            width: 36px;
            line-height: 36px;
            box-shadow: none;
            padding: 0;
            font-size: 20px;
            border-radius: 50%;
        }

        .dropdown-menu {
            box-shadow: $box-shadow;
            border: 1px solid $border-color
        }
    }
}


.chat-conversation {
    scroll-behavior: smooth;
    li {
        clear: both;
    }

    .chat-avatar {
        float: left;
        margin-right: 8px;

        img {
            width: 36px;
            height: 36px;
            border-radius: 50%;
        }
    }

    .chat-day-title {
        position: relative;
        text-align: center;
        margin-bottom: 24px;
        margin-top: 12px;

        .title {
            background-color: $light;
            position: relative;
            z-index: 1;
            padding: 3px 16px;
            border-radius: 30px;
        }

        &:before {
            content: "";
            position: absolute;
            width: 100%;
            height: 1px;
            left: 0;
            right: 0;
            background-color: $light;
            top: 10px;
        }

        .badge {
            font-size: 12px;
        }
    }

    .conversation-list {
        display: flex;

        margin-bottom: 24px;
        // display: inline-block;
        position: relative;

        &.reverse{
            flex-direction: row-reverse;
            .chat-avatar{ margin-right: 0; margin-left: 8px;}
            .ctext-wrap{
                .conversation-name{ text-align: right;}
                .chat-time{ text-align: left;}
            }
        }

        .ctext-wrap {
            overflow: hidden;
            min-width: calc(20% - 44px);
            max-width: calc(50% - 44px);
            .conversation-name {
                font-weight: $font-weight-semibold;
                margin-bottom: 7px;
            }
        }

        .ctext-wrap-content {
            padding: 12px 16px;
            background-color: $primary;
            border-radius: $border-radius;
            color: $white;
        }

        .chat-time {
            margin-top: 7px;
            font-size: 12px;
            text-align: right;
        }
    }

    .right {
        .conversation-list {
            float: right;

            .conversation-name {
                text-align: right;
            }

            .ctext-wrap-content {
                background-color: $light;
                text-align: right;
                color: $gray-600;
            }

            .chat-time {
                text-align: left;
            }

        }

    }

}

.chat-input-section {
    background-color: $card-bg;
    border-radius: $border-radius;
}

.chat-input {
    background-color: $light !important;
    border-color: $light !important;
}

.chat-input-links {
    position: absolute;
    left: 16px;
    top: 50%;
    transform: translateY(-50%);

    li {
        a {
            font-size: 16px;
            line-height: 36px;
            padding: 0px 4px;
            display: inline-block;
        }
    }
}

.chat-send {
    @media (max-width: 575.98px) {
        min-width: auto;
    }
}