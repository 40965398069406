/* add css module styles here (optional) */
.details-content-wrapper {
    border-radius: 7px;
    background-color: #fff;
    box-shadow: 0px 0px 4px 0px #4f666e 40;
}
.risk-managment-card {
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 0.5rem;
    padding: 1rem;
    background: rgba(255, 255, 255, 1);
    min-height: 412px;
}
.risk-managment-matrix {
    border-collapse: collapse;
    width: 100%;
    margin-inline: auto;
}
.risk-managment-matrix caption .dt-skeleton {
    animation: skeleton-loading 1s linear infinite alternate;
    border-radius: 3px;
    display: inline-block;
}
.risk-managment-matrix caption .dt-skeleton.dt-skeleton-table-caption-badge {
    width: 120px;
    height: 44px;
}
.risk-managment-matrix tr td {
    border-bottom: 1px solid #fff;
    border-right: 1px solid #fff;
    vertical-align: middle;
    text-align: center;
    font-weight: 500;
}
.risk-managment-matrix tr td:last-child {
    border-right: none;
}
.risk-managment-matrix tr td .dt-skeleton {
    width: 120px;
    height: 48px;
    margin-left: 5px;
    animation: skeleton-loading 1s linear infinite alternate;
    border-radius: 3px;
    display: inline-block;
}
.risk-managment-matrix tr td > div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.risk-managment-matrix tr td.dt-matrix-column {
    height: 48px;
    border: 1px solid #fff;
    position: relative;
}
.risk-managment-matrix tr td.dt-matrix-column .else-risks-drop-btn {
    background: transparent;
    border: none;
    padding: 0px;
    font-size: 18px;
    font-weight: bold;
    height: 14px;
    line-height: 1;
}
.risk-managment-matrix tr td.dt-matrix-column.dt-skeleton {
    animation: skeleton-loading 1s linear infinite alternate;
}
.risk-managment-matrix tr td.dt-matrix-column > div {
    flex-direction: row;
    position: absolute;
    bottom: 0px;
    left: 4px;
    background: transparent;
    flex-wrap: wrap;
    width: 90%;
    justify-content: flex-start;
}
.risk-managment-matrix tr td.dt-matrix-column > span {
    position: absolute;
    right: 8px;
    bottom: 0px;
}
.risk-managment-matrix tr td.dt-matrix-column .dt-matrix-risk-bullet {
    height: 16px;
    border-radius: 100%;
    background-color: #828282;
    border: 2px solid #fff;
    margin: 0px 2px 2px 0px;
    flex: 0 0 16px;
    cursor: default;
}
.risk-managment-matrix tr td.dt-matrix-column .dt-matrix-risk-bullet:hover {
    transform: scale(1.1);
}
@media (max-width: 768px) {
    .risk-managment-matrix tr td.dt-matrix-column .dt-matrix-risk-bullet:nth-of-type(1n+3) {
        display: none !important;
   }
}
@media (max-width: 1024px) {
    .risk-managment-matrix tr td.dt-matrix-column .dt-matrix-risk-bullet:nth-of-type(1n+4) {
        display: none !important;
   }
}
@media (max-width: 1280px) {
    .risk-managment-matrix tr td.dt-matrix-column .dt-matrix-risk-bullet:nth-of-type(1n+7) {
        display: none !important;
   }
}
@media (max-width: 1920px) {
    .risk-managment-matrix tr td.dt-matrix-column .dt-matrix-risk-bullet:nth-of-type(1n+16) {
        display: none !important;
   }
}

@media (min-width: 1920px) {
    .risk-managment-matrix tr td.dt-matrix-column .dt-matrix-risk-bullet:nth-of-type(1n+16) {
        display: none !important;
   }
}
.risk-managment-matrix tr td.dt-matrix-column .dt-matrix-risk-bullet-else {
    font-size: 12px;
    color: #fff;
    height: 12px;
    line-height: 1;
    font-weight: bold;
    margin-left: 2px;
}
.risk-managment-matrix tr td.dt-matrix-y-axis {
    height: 47px;
    max-width: 38px;
    width: 38px;
    background-color: #f0f1ff;
    border: 1px solid #fff;
}
.risk-managment-matrix tr td.dt-matrix-y-axis .dt-matrix-y-axis-value.dt-skeleton {
    width: 40px;
}
.risk-managment-matrix tr td.dt-matrix-y-axis .dt-matrix-y-axis-title.dt-skeleton {
    width: 70%;
}
.risk-managment-matrix tr td.dt-matrix-y-axis-label {
    background-color: #f0f1ff;
    border: 1px solid #fff;
    font-weight: 500;
    max-width: 38px;
    width: 38px;
}
.risk-managment-matrix tr td.dt-matrix-x-axis-label {
    border-radius: 5px 5px 0 0;
    line-height: 30px;
    padding: 10px 0;
    background-color: #f0f1ff;
    border: 1px solid #fff;
    font-weight: bold;
}
.risk-managment-matrix tr td.dt-matrix-x-axis-label > div {
    margin-bottom: 0;
    line-height: 25px;
    flex-direction: row;
}
.risk-managment-matrix tr td.dt-matrix-x-axis {
    line-height: 35px;
    background-color: #f0f1ff;
    border: 1px solid #fff;
}
.risk-managment-matrix tr td.dt-matrix-x-axis > div {
    margin-bottom: 0;
    flex-direction: column;
    line-height: 25px;
    padding: 10px 0;
}
.risk-managment-matrix tr td.dt-matrix-x-axis > div .dt-matrix-x-axis-title {
    margin-bottom: 10px;
}
.risk-managment-matrix tr td.dt-matrix-x-axis > div .dt-matrix-x-axis-title.dt-skeleton {
    width: 70%;
    display: block;
}
.risk-managment-matrix tr td.dt-matrix-x-axis > div .dt-skeleton {
    width: 50px;
    height: 25px;
    margin-left: 5px;
    animation: skeleton-loading 1s linear infinite alternate;
    border-radius: 3px;
    display: inline-block;
}
@keyframes skeleton-loading {
    0% {
        background-color: #c2cfd6;
   }
    100% {
        background-color: #f0f3f5;
   }
}
.dt-list-col-bullet {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    background-color: var(--color-primary);
    color: #fff;
    display: inline-flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    text-transform: uppercase;
    line-height: 30px;
}
.dt-list-col-bullet-text {
    min-width: 50%;
    width: calc(100% - (30px + 15px));
    margin-left: 10px;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.dt-list-col-bullet-text.dt-skeleton {
    width: 50%;
    display: inline-block;
    height: 25px;
}
.dt-risk-rating-bullet {
    width: 20px;
    height: 20px;
    border-radius: 100%;
    display: block;
}
.tpdd-pagination-style-1 {
    display: flex;
    justify-content: start;
    align-items: center;
    flex-flow: row-reverse;
}
.tpdd-pagination-style-1 .pagination {
    margin: 0px;
}
.tpdd-pagination-style-1 .pagination .page-item {
    margin: 0px 8px;
}
.tpdd-pagination-style-1 .pagination .page-item a {
    border: none;
    border-radius: 8px;
    min-width: 44px;
    height: 44px;
    text-align: center;
    padding: 6px 10px 6px 10px;
    background-color: #f5f4f7;
    color: #1a2052;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
}
.tpdd-pagination-style-1 .pagination .page-item a span {
    display: flex;
    justify-content: center;
    align-items: center;
}
.tpdd-pagination-style-1 .pagination .page-item a span i {
    font-size: 18px;
}
.tpdd-pagination-style-1 .pagination .page-item.active a {
    background-color: #1a2052;
    color: #fff;
}
.tpdd-pagination-style-1 .react-bs-table-sizePerPage-dropdown {
    margin-right: 2px;
}
.tpdd-pagination-style-1 .react-bs-table-sizePerPage-dropdown button {
    background-color: #f5f4f7;
    padding: 6px 10px 6px 10px;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 500;
    border: none;
    height: 44px;
    color: #1a2052 !important;
    min-width: 76px;
}
.tpdd-pagination-style-1 .react-bs-table-sizePerPage-dropdown .dropdown-menu .dropdown-item a {
    display: block;
    color: #1a2052;
}
.nav-tabs.custom-style-1 {
    display: inline-flex;
    background-color: #fff;
    border: none;
}
.nav-tabs.custom-style-1 .nav-item {
    background-color: #d1d2d6;
}
.nav-tabs.custom-style-1 .nav-item:has(+.active) {
    border-bottom-right-radius: 8px;
}
.nav-tabs.custom-style-1 .nav-item:has(+.active) .nav-link {
    border-bottom-right-radius: 8px;
}
.nav-tabs.custom-style-1 .nav-item:has(> .active) + .nav-item {
    border-bottom-left-radius: 8px;
}
.nav-tabs.custom-style-1 .nav-item:has(> .active) + .nav-item .nav-link {
    border-bottom-left-radius: 8px;
}
.nav-tabs.custom-style-1 .nav-link {
    font-size: 16px !important;
    font-weight: 400;
    color: #000 !important;
    border: none;
    border-radius: 0px;
    padding: 23px !important;
}
.nav-tabs.custom-style-1 .nav-link.active {
    font-weight: 600;
}
.nav-tabs.custom-style-1 .nav-link:hover {
    color: #000 !important;
}
._loading_overlay_overlay {
    color: #000 !important;
    background: transparent !important;
}
.custom-navigator {
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.dt-task-status {
    padding: 0 10px;
    line-height: 25px;
    color: #ffffff;
    border-radius: 4px;
    font-size: 12px;
    display: inline-block;
    width: auto;
    &.dt-skeleton {
      min-width: 50%;
      height: 25px;
    }
  }
.risk-management-module{
  .custom-table-filter .dropdown .filters-container {
        min-width: 590px !important;
    }
}