.dt-field {
  display: block;
  min-height: 25px;
  height: auto;
  line-height: 25px;

  &.dt-skeleton {
    animation: skeleton-loading 1s linear infinite alternate;
    border-radius: 3px;
    width: 150px;
    max-width: 100%;

    &.dt-select-list {
      height: 38px;
      width: 100%;
      margin-bottom: 0;
    }

    &.dt-update-date {
      width: 150px;
      max-width: 100%;
      height: 14px;
      border-radius: 0.25rem;
      margin-top: 7px;
    }

    &.dt-accordion {
      width: 100%;
      height: 274px;
      border-radius: 0.25rem;
    }

    &.dt-content-title {
      min-width: 30%;
      height: 21px;
      border-radius: 0.25rem;
    }

    &.dt-content-description {
      min-width: 100%;
      height: 40px;
      border-radius: 0.25rem;
    }
  }
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 80%);
  }
  100% {
    background-color: hsl(200, 20%, 95%);
  }
}
