@import "../../../assets/scss/theme.scss";

.apexcharts-canvas {
  text {
    // TODO: Whereis
    // fill: $text-muted !important;

    text-shadow: none !important;
  }
}
