.message-details-box {
    .dropzone{
        min-height: auto;
        border: none;
        background: #F8F9FA;
    }

    font-size: 16;
    border: 1px solid #828282;

    border-radius: 8px;
    display: flex;
    min-height: 514px;
    width: 100%;
    display: flex;
    flex-direction: column;

    .message-details-title {
        margin-bottom: 5px;
        font-weight: 600;
    }

    .message-container {
        width: 100%;
        height: 335px;
        overflow-y: auto;
        padding: 24px;
    }

    .message-loading-container {
        display: flex;
        justify-content: center;
        width: 100%;
    }
    .chat-day-title .title {
        color: black;
        font-size: 14px;
        font-weight: normal;
    }

    .message-search-icon {
        font-size: 16px;
        position: absolute;
        right: 13px;
        top: 0;
        line-height: 38px;
    }

    .message-search-input {
        width: 300px;
        padding-left: 14px;
    }

    .upload-details-container {
        font-size: 11px;
        margin-bottom: 0px;
    }

    .message-input-container{
        background-color: #eff2f7;
        border-radius: 4px;

        .message-input {
        .rdw-editor-main{
            height: 40px;
            overflow: visible;
            border-radius: 4px;
        }
        .public-DraftStyleDefault-block{
            margin: 8px 0px;
        }
        }
    }

    .noborder {
        border: none;
    }

    .drag-main-div{
        position: absolute;
        top: 50%; 
        left: 50%; 
        transform: translate(-50%, -50%); 
        width: calc(100% - 50%);
        max-width: 660px; 
        height: 167px;
        background: rgba(224, 224, 224, 0.5);
        border: 3px dashed #BDBDBD;
        border-radius: 8px;
        z-index: 2;

        .drag-item-div{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        align-content: center;
        height: 100%;
        width: 100%;
        gap: 29px;
        }
    }

    @keyframes fadeIn {
        0% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
    }
    
    .message-show-fade-in {
        animation: fadeIn 1s;
    }
    
    .message-modal-style .modal-content {
        padding-inline: 32px;
    }
    
    .message-modal-style .modal-content .modal-header {
        padding-top: 32px;
        border-bottom: none;
        .close {
            top: 24px;
            right: 32px;
        }
    }
    .message-quote-box {
        height: 100%;
        margin-top: 110px;
    }
    
    .empty-messagebox {
        width: 300px;
        height: 200px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
        display: flex;
        justify-content: center;
        align-items: center;
        > p {
            color: #fff;
            max-width: 50%;
            margin: 0px;
            margin-top: -62px;
            text-align: center;
            font-size: 12px;
        }
    }
    
    .attachment-Card {
        color: #74788d;
        background: #eff2f7;
        border: 1px solid #5664d2;
        border-radius: 4px;
        min-width: 350px;
    }
    
    .file-name{
        cursor: pointer;
    }
}