.table-container {
  width: 100%;
  margin: auto;

  table {
    width: 100%;
    border-collapse: separate; /* Disable collapsing borders */
    border-spacing: 0; /* Remove spacing between cells */
    border: 1px rgba(137, 155, 161, 1) solid;
    border-radius: 12px;
    margin-bottom: 20px;

    th:first-child, td:first-child {
      border-top-left-radius: 12px; /* Round top-left of the first cell in each row */
      border-bottom-left-radius: 12px; /* Round bottom-left of the first cell in each row */
    }

    th:last-child, td:last-child {
      border-top-right-radius: 12px; /* Round top-right of the last cell in each row */
      border-bottom-right-radius: 12px; /* Round bottom-right of the last cell in each row */
    }

    th, td {
      text-align: left;
      padding: 8px;
    }
  }

  .edit-btn, .delete-btn {
    margin-right: 10px;
    cursor: pointer;
  }
}
