label{
    &:not(.bootstrap-default){
        font-size: font-sizes(common, large);
        font-weight: 600;
        color: color(black, 2);
    }
}

input[type=text],
input[type=number],
input[type=email],
select{
    &:not(.bootstrap-default){
        padding: 4px 0px 4px 13px;
        border-color: color(black, 4);
        border-radius: 4px;
        font-size: font-sizes(common, large);
        font-weight: 400;
    }
}