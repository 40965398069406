.page-title-box {
    padding-bottom: $grid-gutter-width;

    .breadcrumb {
        background-color: transparent;
        padding: 0;
    }

    h4 {
        font-size: 15px;
        font-weight: 600;
    }

    .page-title-right {
        display: flex;
        align-items: center;

        @media (max-width: 767.98px) {
            flex-direction: column;
            align-items: flex-start;
            margin-top: 10px;
        }

        @media (max-width: 575.98px) {  // Adjust for even smaller screens like iPhone SE
            h4 {
                font-size: 14px;
            }

            .breadcrumb {
                font-size: 12px;
            }

            align-items: center;
            text-align: center;
            width: 100%;
            margin-top: 10px;

            .breadcrumb-item {
                display: block;
                width: 100%;
                text-align: center;
            }
        }
    }
}


