html[data-module='gd']{
// .riskCard{
//     padding: 20px;
//     border-radius: 20px;
//     background-color: #fff;
// }


.cardBody {
    background-color: #fff;
}


.riskSubHeader {
    display: flex;
    justify-content: space-between;
}

.darkBlueText {
    color: #1A2052;
    line-height: 27.44px;
    font-size: 14px;
    font-weight: 700px;
}


.category{
    // background-color: #5664D233;
    // color: #5664D2;
    padding: 6px 20px 5px 14px;
    border-radius: 10px;
}

.flag{
    background-color: #F5F4F7;
    padding: 6px;
    border-radius: 10px;
    color: #1A2052;
    cursor: pointer;
}

.selectedFlag{
    background-color: #FC5959;
    padding: 6px;
    border-radius: 10px;
    color: #F5F4F7;
    height: max-content;
    cursor: pointer;
}

.mitFlag{
    background-color: #fff;
    padding: 6px;
    border-radius: 10px;
    color: #1A2052;
    height: max-content;
    cursor: pointer;
}

// .selectedMitFlag{
//     background-color: #FC5959;
//     padding: 6px;
//     border-radius: 10px;
//     color: #F5F4F7;
//     height: max-content;
//     cursor: pointer;
// }

Card{
    width: 100%;
}

.sticky-row {
    position: sticky;
    // top: 0;
    width: 100%;
    z-index: 1000; // You can adjust this value as needed
    background-color: white; // Or any background to ensure text is readable
  }
}