.wizard-steps-container{
    padding: 32px;
    box-shadow: 0px 4px 4px 0px #00000040;
    border-radius: 12px;
    border: none;
    background-color: color(white, 1);

    .twitter-bs-wizard{
        position: relative;

        .twitter-bs-wizard-nav{
            &::before{
                content: none;
            }

            .nav-item{
                position: relative;

                .nav-link{
                    .step-title{
                        font-size: font-sizes(common, large);
                        font-weight: 400;
                    }

                    &.current{
                        .step-title{
                            font-weight: 700;
                        }
                    }
                }
                
                &:last-child{
                    &::before{
                        content: none;
                    }
                }

                &::before{
                    content: '';
                    background: #E9ECEF;
                    position: absolute;
                    width: 100%;
                    height: 2px;
                    left: 50%;
                    top: 31px;
                }

                &.completed{
                    &::before{
                        background: #5664D2;
                    }
                }

                .nav-link{
                    .step-number{
                        border-color: #DEE2E6;
                    }

                    &.active{
                        .step-number{
                            border-color: #5664d2;
                        }
                    }

                    &.current{
                        .step-number{
                            &::before{
                                content: '';
                                position: absolute;
                                width: 100%;
                                height: 100%;
                                border: 1px solid #fff;
                                display: block;
                                border-radius: 100%;
                            }
                        }
                    }
                }
            }
        }

        #bar {
            position: absolute;
            top: 50%;
            width: 100%;
        }
    }    
}

.wizard-steps-content-container{
    padding: 32px;
    box-shadow: 0px 4px 4px 0px #00000040;
    border-radius: 12px;
    border: none;
    background-color: color(white, 1);
}