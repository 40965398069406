.recording-component {
  /* text-align: center; */
}

.message-container {
  margin-bottom: 20px;
}

.message-label {
  font-size: 18px;
  color: #333;
}

.button-container {
	margin-bottom: 20px;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	.recording-component {
		max-width: 40%;
		> div {
			max-width: 300px;
			canvas {
				max-width: 100%;
			}
		}
	}
}

.button-group {
  justify-content: center;
}

.record-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10rem;
}

.recorded-audio,
.saved-recordings {
  margin-top: 20px;
}

.saved-recordings {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
}

.saved-recordings audio {
  width: 100%;
  max-width: 300px; /* Adjust the width as needed */
}

.label {
  font-size: 16px;
  font-weight: bold;
  color: #333;
}

.playback-button,
.save-button {
  margin-right: 10px;
}

.recording-item {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  margin-bottom: 10px;
  margin-top: 20px;
}

.timestamp {
  flex: 0 0 100px;
  text-align: center;
  align-items: center;
  margin-top: 1rem;
  white-space: nowrap;
}

audio {
  flex: 1;
  margin: 0 10px;
}

.recording-item audio {
  flex: 1;
}

.recording-item .remove-button {
  margin-left: 10px;
}

/* Media Queries for Mobile View */
@media (max-width: 767px) {
  .recording-item {
    flex-direction: column;
    align-items: stretch;
    text-align: left;
  }

  .timestamp {
    flex: none;
    margin-top: 0;
    margin-bottom: 0.5rem;
    white-space: normal;
  }

  .recording-item audio {
    margin: 10px 0;
  }

  .recording-item .remove-button {
    margin-left: 0;
    margin-top: 10px;
  }
}
