.mailer-container {
  display: flex;
  flex-flow: row wrap;
  &.mailTitle {
    position: absolute;
    top: 0;
    left: 110px;
    right: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin-bottom: 0;
  }

  &.checkbox-wrapper-mail {
    margin: 15px 10px 0 20px;
  }
}
