html[data-module='gd']{
    .accordion-item{
        border-radius: 0px !important;
        border: none !important;
    }
    .accordion-button:focus {
        box-shadow: none;
    }

    .accordion-button {
        padding: 0.5rem !important;
        border-radius: 0 !important;
        border-top-left-radius: 8px !important;
        border-top-right-radius: 8px !important;
    }

    .accordion-button.collapsed::after {
       // background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
       background-image: var(--bs-accordion-btn-active-icon);
    }
}