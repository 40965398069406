.tpdd-pagination-style-1{
    display: flex;
    justify-content: start;
    align-items: center;
    flex-flow: row-reverse;

    .pagination{
        margin: 0px;

        .page-item{
            margin: 0px 8px;
            a{
                border: none;
                border-radius: 8px;
                min-width: 44px;
                height: 44px;
                text-align: center;
                padding: 6px 10px 6px 10px;
                background-color: #F5F4F7;
                color: #1A2052;
                font-size: 14px;
                font-weight: 500;
                display: flex;
                justify-content: center;
                align-items: center;

                span{
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    i{
                        font-size: 18px;
                    }
                }
            }

            &.active{
                a{
                    background-color: #1A2052;
                    color: #FFFFFF;
                }
            }
        }
    }

    .react-bs-table-sizePerPage-dropdown{
        margin-right: 2px;

        button{
            background-color: #F5F4F7;
            padding: 6px 10px 6px 10px;
            border-radius: 8px;
            font-size: 14px;
            font-weight: 500;
            border: none;
            height: 44px;
            color: #1A2052 !important;
            min-width: 76px;
        }

        .dropdown-menu {
            .dropdown-item a{
                display: block;
                color: #1A2052;
            }
        }
    }
}