html[data-module='gd']{
.dt-matrix {
	border-collapse: collapse;
	width: 100%;
	margin-top: 60px;

	caption {
		.dt-skeleton {
			animation: skeleton-loading 1s linear infinite alternate;
			border-radius: 3px;
			display: inline-block;
			&.dt-skeleton-table-caption-badge {
				width: 120px;
				height: 44px;
			}
		}
	}

	tr {
		td {
			border-bottom: 1px solid #ffffff;
			border-right: 1px solid #ffffff;
			vertical-align: middle;
			text-align: center;
			font-weight: 500;

			&:last-child {
				border-right: none;
			}

			.dt-skeleton {
				width: 50px;
				height: 25px;
				margin-left: 5px;
				animation: skeleton-loading 1s linear infinite alternate;
				border-radius: 3px;
				display: inline-block;
			}

			> div {
				display: flex;
				align-items: center;
				justify-content: center;
				flex-direction: column;
			}

			&.dt-matrix-column {
				height: 70px;
				border: 1px solid #ffffff;
				&.dt-skeleton {
					animation: skeleton-loading 1s linear infinite alternate;
				}

				> div {
					flex-direction: row;
				}

				.dt-matrix-risk-bullet {
					width: 36px;
					height: 36px;
					display: flex;
					align-items: center;
					justify-content: center;
					font-weight: bold;
					font-size: 13px;
					border-radius: 100%;
					background-color: #ffffff;
					border: 3px solid #dadada;
					margin: 0 auto;
					transition: transform 0.3s ease;
					&:hover {
						transform: scale(1.1);
					}
				}
			}

			&.dt-matrix-y-axis {
				height: 70px;
				background-color: rgb(240, 241, 255);
				border: none;
				.dt-matrix-y-axis-value {
					&.dt-skeleton {
						width: 40px;
					}
				}
				.dt-matrix-y-axis-title {
					&.dt-skeleton {
						width: 70%;
					}
				}
			}

			&.dt-matrix-y-axis-label {
				height: 70px;
				background-color: rgb(240, 241, 255);
				border: none;
				font-weight: bold;
				padding-left: 20px;
				writing-mode: sideways-lr;
			}

			&.dt-matrix-x-axis-label {
				border-radius: 5px 5px 0 0;
				line-height: 30px;
				padding: 10px 0;
				background-color: rgb(240, 241, 255);
				border: none;
				font-weight: bold;
				> div {
					margin-bottom: 0;
					line-height: 25px;
					flex-direction: row;
				}
			}

			&.dt-matrix-x-axis {
				line-height: 35px;
				background-color: rgb(240, 241, 255);
				border: none;
				> div {
					margin-bottom: 0;
					flex-direction: column;
					line-height: 25px;
					padding: 10px 0;
					.dt-matrix-x-axis-title {
						margin-bottom: 10px;
						&.dt-skeleton {
							width: 70%;
							display: block;
						}
					}

					.dt-skeleton {
						width: 50px;
						height: 25px;
						margin-left: 5px;
						animation: skeleton-loading 1s linear infinite alternate;
						border-radius: 3px;
						display: inline-block;
					}
				}
			}
		}
	}
}

.dt-list {
	width: 100%;
	font-weight: 500;
	table-layout: fixed;

	thead {
		border-bottom: 1px solid #dadada;
	}

	tr {
		width: 100%;
		padding: 10px 0;
		border-bottom: 1px solid #dadada;

		&:last-child {
			border: none;
		}

		td {
			padding: 10px;
			> div {
				width: 100%;
				overflow: hidden;
				display: flex;
				align-items: center;
				justify-content: flex-start;
				.dt-skeleton {
					animation: skeleton-loading 1s linear infinite alternate;
					border-radius: 3px;
					margin-bottom: 0;
				}
			}

			&:first-child {
				padding-left: 0;
			}

			&:last-child {
				padding-right: 0;
			}
		}
	}

	&.dt-risks-list {
		.dt-risk-rating-bullet {
			width: 20px;
			height: 20px;
			border-radius: 100%;
			display: block;
		}

		.dt-list-col-result-id {
			font-weight: bold;
			text-transform: uppercase;
			min-width: 50%;
			height: 25px;
		}

		.dt-list-col-result-description {
			display: block;
			min-width: 50%;
			height: 25px;

			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		.dt-list-col-bullet {
			width: 30px;
			height: 30px;
			border-radius: 100%;
			background-color: var(--color-primary);
			color: #ffffff;

			display: inline-flex;
			text-align: center;
			align-items: center;
			justify-content: center;

			font-weight: bold;
			text-transform: uppercase;
			line-height: 30px;
		}

		.dt-list-col-bullet-text {
			min-width: 50%;
			width: calc(100% - (30px + 15px));
			margin-left: 15px;
			display: inline-block;

			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;

			&.dt-skeleton {
				width: 50%;
				display: inline-block;
				height: 25px;
			}
		}

		.dt-list-col-date {
			font-weight: 500;
			&.dt-skeleton {
				width: 80%;
				height: 25px;
			}
		}
	}
}

.ai-base-risk-assessment-button {
	img {
		width: 18px;
		height: 18px;
    margin-right: 10px;
		display: inline-block;
		object-fit: contain;
    margin-top:-2px ;
	}
}

.create-risk-modal {
	.create-risk-modal-body {
		position: relative;
		.ai-overlay {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			z-index: 10;
			background-color: #ffffff;
			display: flex;
			align-items: stretch;
			justify-content: center;
			transition: opacity ease 0.3s;
			.lottie-animation-box {
				display: flex;
				align-items: start;
				justify-content: center;
				padding-top: 200px;
			}
			.ai-risks-list {
				width: 100%;
				display: flex;
				flex-direction: column;
				gap: 44px;
				padding: 24px;
				.risk {
					display: flex;
					flex-direction: column;
					gap: 5px;
					padding: 15px;
					background: #fafafa;
					border-radius: 6px;
					border: 1px solid #f7f4f4;
					cursor: pointer;
					opacity: 0.7;
					transition: opacity 0.3s ease;
					&:hover {
						opacity: 1;
					}
					&:last-child {
						margin-bottom: 24px;
					}
					p {
						margin-bottom: 0;
					}
					.risk-title,
					.risk-description,
					.risk-mitigations,
					.task-mitigation {
						background: #ffffff;
						padding: 10px 10px;
						border-radius: 6px;
						span {
							font-weight: bold;
						}
					}
					.risk-mitigations {
						margin-top: 20px;
					}
					.task-mitigation {
						padding-left: 30px;
						display: flex;
						align-items: flex-start;
						justify-content: flex-start;
						p {
							margin-bottom: 0;
							&.task-mitigation-description {
								margin-left: 4px;
							}
						}
					}
				}
			}
		}
	}
}

@keyframes skeleton-loading {
	0% {
		background-color: hsl(200, 20%, 80%);
	}
	100% {
		background-color: hsl(200, 20%, 95%);
	}
}
}