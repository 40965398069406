.select2-selection__control{
    &:not(.select2-default){
        min-height: auto;
        border-color: color(black, 4);
        border-radius: 4px;
        font-size: font-sizes(common, large);
        font-weight: 400;
    
        .select2-selection__value-container{
            padding: 0px 8px;
    
            
        }
    
        .select2-selection__indicator-separator{
            display: none;
        }
    
        .select2-selection__dropdown-indicator{
            padding: 4px; 
            color: color(black, 4);
        }
    }
}
