.history-logs-page {
	min-height: 650px;

	.page-title {
		font-size: 20px;
		color: #1a2052;
		font-weight: 800;
		margin: 0 10px;
		line-height: 47px;
	}

	.page-body {
		background-color: #fff;
		border-radius: 6px;
		margin-bottom: 35px;

		.alert-wrapper {
			padding: 20px 30px 0;
		}
	}
}
