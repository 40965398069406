.popup {
    position: fixed;
    top: 85%;
    right: 0%;
    transform: translate(-10%, -50%);
    background-color: rgb(250, 255, 255);
    padding: 20px;
    border: 1px solid #ccc;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    z-index: 9999;
  }
  
  .popup-inner {
    max-width: 400px;
    margin: 0 auto;
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 20px;
    cursor: pointer;
    border: none;
    background: transparent;
  }
  