.tag {
    display: inline-block;
    background-color: #e9ecef;
    border-radius: 4px;
    padding: 3px 6px 3px 6px;
    margin: 2px 4px;
    font-size: 14px;
    background-color: rgba(248, 249, 250, 1);
    cursor: pointer;
    border: 1px solid rgba(206, 212, 218, 1);
  
    .tag-close {
      margin-left: 5px;
      cursor: pointer;
    }
  
    &:hover {
      background-color: darken(
        #e9ecef,
        5%
      ); // Darkens the tag background on hover
    }
  }