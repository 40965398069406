.dt-matrix {
  border-collapse: collapse;
  width: 100%;
  margin-top: 60px;

  caption {
    .dt-skeleton {
      animation: skeleton-loading 1s linear infinite alternate;
      border-radius: 3px;
      display: inline-block;
      &.dt-skeleton-table-caption-badge {
        width: 120px;
        height: 44px;
      }
    }
  }

  tr {
    td {
      border-bottom: 1px solid #ffffff;
      border-right: 1px solid #ffffff;
      vertical-align: middle;
      text-align: center;
      font-weight: 500;

      &:last-child {
        border-right: none;
      }

      .dt-skeleton {
        width: 50px;
        height: 25px;
        margin-left: 5px;
        animation: skeleton-loading 1s linear infinite alternate;
        border-radius: 3px;
        display: inline-block;
      }

      > div {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
      }

      &.dt-matrix-column {
				height: 48px;
				border: 1px solid #ffffff;
				position: relative;

				.else-risks-drop-btn {
					background: transparent;
					border: none;
					padding: 0px;
					font-size: 18px;
					font-weight: bold;
					height: 14px;
					line-height: 1
				}

				&.dt-skeleton {
					animation: skeleton-loading 1s linear infinite alternate;
				}

				> div {
					flex-direction: row;
					position: absolute;
					bottom: 0px;
					left: 4px;
					background: transparent;
					flex-wrap: wrap;
					width: 90%;
					justify-content: flex-start;
				}

				> span{
					position: absolute;
					right: 8px;
					bottom: 0px;
				}

				.dt-matrix-risk-bullet {
					height: 16px;
					border-radius: 100%;
					background-color: #828282;
					border: 2px solid #ffffff;
					margin: 0px 2px 2px 0px;
					flex: 0 0 16px;
					cursor: default;
					&:hover {
						transform: scale(1.1);
					}

					@media (max-width: 768px) {
						&:nth-of-type(1n+8){
							display: none;
						}
					}

					@media (max-width: 1024px) {
						&:nth-of-type(1n+9){
							display: none;
						}
					}

					@media (max-width: 1280px) {
						&:nth-of-type(1n+13){
							display: none;
						}
					}

					@media (max-width: 1920px) {
						&:nth-of-type(1n+22){
							display: none;
						}
					}
				}
				
				.dt-matrix-risk-bullet-else{
					font-size: 12px;
					color: #fff;
					height: 12px;
					line-height: 1;
					font-weight: bold;
					margin-left: 2px;
				  }
			}

      &.dt-matrix-y-axis {
        height: 70px;
        background-color: rgb(240, 241, 255);
        border: none;
        .dt-matrix-y-axis-value {
          &.dt-skeleton {
            width: 40px;
          }
        }
        .dt-matrix-y-axis-title {
          &.dt-skeleton {
            width: 70%;
          }
        }
      }

      &.dt-matrix-y-axis-label {
        height: 70px;
        background-color: rgb(240, 241, 255);
        border: none;
        font-weight: bold;
        padding-left: 20px;
        writing-mode: sideways-lr;
      }

      &.dt-matrix-x-axis-label {
        border-radius: 5px 5px 0 0;
        line-height: 30px;
        padding: 10px 0;
        background-color: rgb(240, 241, 255);
        border: none;
        font-weight: bold;
        > div {
          margin-bottom: 0;
          line-height: 25px;
          flex-direction: row;
        }
      }

      &.dt-matrix-x-axis {
        line-height: 35px;
        background-color: rgb(240, 241, 255);
        border: none;
        > div {
          margin-bottom: 0;
          flex-direction: column;
          line-height: 25px;
          padding: 10px 0;
          .dt-matrix-x-axis-title {
            margin-bottom: 10px;
            &.dt-skeleton {
              width: 70%;
              display: block;
            }
          }
          .dt-matrix-x-axis-value {
          }
          .dt-skeleton {
            width: 50px;
            height: 25px;
            margin-left: 5px;
            animation: skeleton-loading 1s linear infinite alternate;
            border-radius: 3px;
            display: inline-block;
          }
        }
      }
    }
  }
}

.dt-list {
  width: 100%;
  font-weight: 500;
  table-layout: fixed;

  thead {
    border-bottom: 1px solid #dadada;
  }

  tr {
    width: 100%;
    padding: 10px 0;
    border-bottom: 1px solid #dadada;

    &:last-child {
      border: none;
    }

    td {
      padding: 10px;
      > div {
        width: 100%;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        .dt-skeleton {
          animation: skeleton-loading 1s linear infinite alternate;
          border-radius: 3px;
          margin-bottom: 0;
        }
      }

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }
    }
  }

  &.dt-tasks-list {
    .dt-task-priority,
    .dt-task-deletion-date {
      min-width: 50%;
      height: 25px;
    }

    .dt-task-id {
      font-size: 14px;
      font-weight: bold;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: auto;
      max-width: 100%;
      display: block;
      line-height: 25px;
      &.dt-skeleton {
        display: inline-block;
        width: auto;
        min-width: 50%;
        height: 25px;
      }

      &:not(.dt-skeleton):hover {
        color: var(--bs-primary);
      }
    }

    .dt-task-title {
      font-size: 14px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: auto;
      max-width: 100%;
      display: block;
      line-height: 25px;
      &.dt-skeleton {
        display: inline-block;
        width: auto;
        min-width: 50%;
        height: 25px;
      }

      &.hoverable:hover {
        color: var(--bs-primary);
      }
    }

    .dt-task-creation-date,
    .dt-task-deadline-date {
      font-size: 14px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: auto;
      max-width: 100%;
      display: block;
      line-height: 25px;
      &.dt-skeleton {
        display: inline-block;
        width: auto;
        min-width: 50%;
        height: 25px;
      }
    }

    .dt-task-priority {
      text-transform: capitalize;
    }

    .dt-task-status {
      padding: 0 10px;
      line-height: 25px;
      color: #ffffff;
      border-radius: 4px;
      font-size: 12px;
      display: inline-block;
      width: auto;
      &.dt-skeleton {
        min-width: 50%;
        height: 25px;
      }
    }

    .dt-risk-rating-bullet {
      width: 20px;
      height: 20px;
      border-radius: 100%;
      display: block;
    }

    .dt-list-col-result-id {
      font-weight: bold;
      text-transform: uppercase;
      min-width: 50%;
      height: 25px;
    }

    .dt-list-col-bullet {
      width: 30px;
      height: 30px;
      border-radius: 100%;
      background-color: var(--color-primary);
      color: #ffffff;

      display: inline-flex;
      text-align: center;
      align-items: center;
      justify-content: center;

      font-weight: bold;
      text-transform: uppercase;
      line-height: 30px;
    }

    .dt-list-col-bullet-text {
      width: calc(100% - (30px + 15px));
      margin-left: 15px;
      display: inline-block;

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &.dt-skeleton {
        width: 50%;
        display: inline-block;
        height: 25px;
      }
    }

    .dt-list-col-date {
      font-weight: 500;
      &.dt-skeleton {
        width: 80%;
        height: 25px;
      }
    }
  }
}

.dt-task-manager {
  display: flex;
  align-items: center;

  .d-inline-flex {
    width: 30px;
    height: 30px;
    margin-right: 9px;
    font-size: 13px;
    color: #fff;
    padding-top: 3px;
    border-radius: 50%; /* Added to create a circle */
    background-color: #007bff; /* Adjust the background color as needed */
    text-align: center;
    line-height: 30px; /* Center the content vertically */
  }

  .dt-skeleton {
    animation: skeleton-loading 1s linear infinite alternate;
    border-radius: 3px;
    margin-bottom: 0;
  }

  .text-container {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 5rem; /* Set the maximum width here */
  }
}


@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 80%);
  }
  100% {
    background-color: hsl(200, 20%, 95%);
  }
}


