.third-party-home-page {
  height: 1009px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-repeat: no-repeat;
  background-size: cover;
  // margin-top: 70px;
  padding: 86px 0px;
  overflow-x: hidden;
  position: relative;

  h1 {
    font-size: 40px;
    margin-top: 100px;
    font-weight: 800;
    color: #000;
    text-align: center;
  }

  .main-container {
    // position: absolute;
    bottom: 86px;
    display: flex;
    width: 100%;
    gap: 30px;
    flex-wrap: wrap; /* Allow wrapping for smaller screens */
    justify-content: center; /* Center align for smaller screens */
  }

  .new-questionnaire-box,
  .existing-questionnaire-box {
    width: 45%; /* Adjust width for responsiveness */
    max-width: 500px; /* Max width to prevent too wide elements */
    height: 445px;
    background-color: rgba(255, 255, 255, 0.6);
    box-shadow: 0px 4px 25px 0px rgba(255, 255, 255, 0.5);
    padding: 23px;
    gap: 62px;
    transition: width 0.8s ease-in-out;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
    cursor: pointer;
    color: #000000;

    a,
    button {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    button {
      width: 180px;
      height: 43px;
      box-shadow: 0px 4px 4px 0px #00000040;
      border-radius: 7px;
      font-size: 25px;
      font-weight: 900;
    }
  }

  .new-questionnaire-box {
    border-radius: 0px 300px 300px 0px;
  }

  .existing-questionnaire-box {
    border-radius: 300px 0px 0px 300px;
  }

  .existing-questionnaire-box:hover,
  .new-questionnaire-box:hover {
    width: 85%;
  }

  // @media (min-width: 480px and max-width: 768px) {
    @media (min-width: 480px) and (max-width: 768px) {
    .new-questionnaire-box,
    .existing-questionnaire-box {
      width: 90%; /* Adjust width for smaller screens */
      border-radius: 20px; /* Simplified border-radius for smaller screens */
      height: auto;
    }
    .existing-questionnaire-box:hover,
    .new-questionnaire-box:hover {
      width: 90%;
    }
    .main-container {
      // position: relative;
      bottom: 20px; /* Adjust positioning for smaller screens */
    }
    
    h1 {
      font-size: 24px; /* Adjust font size for smaller screens */
      margin-top: 20px; /* Adjust margin for smaller screens */
    }
  }

  @media (max-width: 480px) {
    .new-questionnaire-box,
    .existing-questionnaire-box {
      width: 100%; /* Full width for very small screens */
      height: auto; /* Adjust height for smaller screens */
    }
    button {
      width: 150px;
      height: 40px;
      font-size: 18px; /* Adjust button font size for smaller screens */
    }
    h1 {
      font-size: 20px; /* Further adjust font size for very small screens */
    }
  }
}
