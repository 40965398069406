/* .link-span {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}

.link-span:hover {
  text-decoration: none; 
} */



/* Media query for mobile devices (up to 767px wide) */
@media screen and (max-width: 767px) {
  }
  
  /* Media query for tablets (768px to 991px wide) */
  @media screen and (min-width: 768px) and (max-width: 991px) {
  }
  
  
  /* Media query for laptops (992px and wider) */
  @media screen and (min-width: 992px) {
    .requiredLabel {
      padding-left: 47px;
    }
    .consCol {
        display: flex;
        justify-content: flex-end;
        /* position: absolute; */
    }
  }