.software-details-page {
  .details-content-wrapper {
    border-radius: 7px;
    background-color: #fff;
    box-shadow: 0px 0px 4px 0px #4f666e40;
  }

  .accordion-item {
    margin-bottom: 16px;
    border: none;
    border-radius: 8px !important;
    overflow: hidden;
    box-shadow: 0px 4px 4px 0px $box-shadow-color;
    position: relative;

    .form-control {
      background: transparent;
    }

    label {
      color: $labels-font-color;
      font-size: $font-size-md;
      font-weight: $font-weight-normal;
    }

    &.active {
      border: 1px solid #4f4f4f;

      .accordion-header button {
        background: #ffffff;
        box-shadow: none;
      }
    }

    .accordion-collapse {
      background-color: #ffffff;
    }

    .accordion-header {
      background: #ffffff;
      &.section-header {
        .accordion-button  {
          // &.collapsed {
            padding: 0px !important;
          // }
        }
      }
      

      button {
        color: #000;
        font-weight: bold;
        padding: 16px;
        height: 92px;
        border-radius: 8px;

        

        > * {
          position: relative;
          z-index: 1;
          background: transparent;
          color: $questionnaire-headers-font-color;
          font-size: $questionnaire-headers-font-size;
          font-weight: $questionnaire-headers-font-weight;
        }

        img {
          width: 50px;
          margin-right: 12px;
        }
      }
    }

    .avatar-group {
      .avatar-group-item {
        width: 40px;
      }
    }

    .products-list {
      padding: 0px 16px 0px 16px;
      border: 1px solid #899ba1;
      border-radius: 12px;

      input[type="text"] {
        padding: 9px 0px 9px 17px;
      }

      button {
        padding: 7px 14px 7px 14px;
      }
    }

    .contact-people {
      padding: 32px;
      display: flex;
      justify-content: center;
      align-items: end;
      flex-flow: column;

      .list {
        width: 100%;

        .item {
          border: 1px solid #828282;
          border-radius: 12px;
          padding: 16px;
        }
      }
    }
  }

  .contact-person-creation-modal {
    .form-label {
      color: #000;
      font-weight: $font-weight-normal;
    }
  }

  .findings-list {
    font-size: 14px;
    color: #282828;
    .finding-list-item {
      position: relative;
      background-color: #fff;
      padding: 16px 30px 16px 30px;
      border-radius: 12px;

      .finding-list-item-checkbox {
        position: absolute;
        left: 6px;
        top: 6px;
      }

      .finging-container {
        .image-container {
          img {
            max-width: 270px;
            border-radius: 14px;
          }
        }

        .finding-content {
          .finding-content-row {
            .flag-btn {
              background-color: transparent;
              border: none;
              padding: 0px;
              margin: 0px;
            }
            .category-title {
              font-size: 12px;
              color: #4f4f4f;
              font-weight: 400;
            }

            .finding-title {
              font-size: 14px;
              font-weight: 600;
              color: #1a1d30;
              cursor: pointer;
            }

            .link {
              font-size: 10px;
              color: #4f4f4f;
              text-decoration: underline !important;
            }

            .time {
              color: #828282;
              font-size: 12px;
            }
          }
        }
      }

      &:hover {
        background-color: #cbd1fc80;
      }
    }
  }

  .processingAcitivity {
    .accordion-item {
      margin-bottom: 16px;
      border: none;
      border-radius: 8px !important;
      overflow: hidden;
      box-shadow: 0px 4px 4px 0px $box-shadow-color;
      position: relative;

      .form-control {
        background: transparent;
        // background-color: #F5F6FF !important;
      }

      label {
        color: $labels-font-color;
        font-size: $font-size-md;
        font-weight: $font-weight-normal;
      }

      &.active {
        // border: 1px solid #4F4F4F;

        .accordion-header button {
          background: #ffffff;
          box-shadow: none;
        }
      }

      .accordion-collapse {
        background-color: #f5f6ff;
      }

      .accordion-header {
        background: #ffffff;

        button {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          color: #000;
          font-weight: bold;
          padding: 16px;
          height: 92px;
          border-radius: 8px;
          border: none !important; // Ensure no border is applied
          box-shadow: none !important; // Remove box shadow if it's considered a border
          background: #F5F6FF;
          
          > * {
            position: relative;
            z-index: 1;
            background: transparent;
            color: $questionnaire-headers-font-color;
            font-size: $questionnaire-headers-font-size;
            font-weight: $questionnaire-headers-font-weight;
          }

          &.collapsed {
            background: #ffffff;
          }

          img {
            width: 30px;
            margin-right: 12px;
          }
        }
      }

      .avatar-group {
        .avatar-group-item {
          width: 40px;
        }
      }

      .full-width-wrapper {
        position: relative;
        width: 100%;
      }
      
      .parent-col {
        position: relative;
      }
      
      .full-width-textarea {
        // position: absolute;
        top: 0;
        left: 0;
        width: 79.8vw; /* Cover the entire viewport width */
        // transform: translateX(-100%); /* Adjust position relative to its parent */
        z-index: 10; /* Ensure it stays above other elements */
        background: white; /* Optional: Ensure background color */
        padding: 10px; /* Optional: Add padding */
      }

      .full-width-text {
        // position: absolute;
        top: 0;
        left: 0;
        width: 79.8vw; /* Cover the entire viewport width */
        // transform: translateX(-100%); /* Adjust position relative to its parent */
        z-index: 10; /* Ensure it stays above other elements */
        background: white; /* Optional: Ensure background color */
        padding: 10px; /* Optional: Add padding */
      }

      .input-container {
        position: relative;
        display: block;
    }
    
      .input-container .input-icon {
          position: absolute;
          right: 5px; /* Adjust this value to position the icon correctly */
          top: 50%;
          transform: translateY(-50%);
          // pointer-events: none;
          cursor: pointer;
      }
      
      .input-container .form-control {
          padding-right: 30px; /* Adjust this value to make room for the icon */
      }
      
      .row-relative {
        position: relative;
      }

    }
  }

  .report-statistics-card {
    font-size: 12px;
    font-weight: 600;
    box-shadow: 0px 4px 4px 0px #00000040;

    h4 {
      font-size: 16px;
    }
    img {
      width: 38px;
    }
  }

  .accordion {
    &.questionnaire {
      .accordion-item {
        &.active {
          border: none;
          .accordion-header {
            button {
              background: #f5f6ff;
            }
          }
        }

        .accordion-header {
          background-color: #f5f6ff;
        }

        .accordion-collapse {
          background-color: #f5f6ff;
        }

        .accordion-body {
          padding: 0px 80px 0px 80px;

          .question-title {
            font-weight: 400;
          }

          .sub-question-container {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            flex-direction: row;
            margin-top: 12px;
            padding: 0px 0px;
            margin-left: 15px;
            margin-bottom: 15px;

            .question {
              flex-basis: 50% !important;
              flex: 2 1;
            }
          }

          .files-container {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            flex-direction: row;

            .file-preview {
              display: flex;
              flex-flow: column;
              justify-content: center;
              align-items: start;
              flex-basis: 33.33%;
              flex: 2 1;
            }
          }
        }
      }
    }
  }

  .key-words {
    .badge {
      background-color: #f8f9fa;
      color: #212529;
      border: 1px solid #ced4da;
      font-weight: 400;
    }
  }

  .message-input {
    border: 1px solid #eff2f7;
    border-radius: 4px;
    background: #eff2f7;
    textarea {
      border: none;
      background: #eff2f7;
      min-height: 92px;
      padding-top: 8px;
      padding-bottom: 0px;
      width: 100%;
    }
  }
}

.ai-risk-result-modal {
  .assessment-title {
    display: none;
  }
}

.custom-table-filter {
  display: flex;
  justify-content: end;
  align-items: center;

  .dropdown-menu {
    left: auto !important;
    right: 0px !important;
    padding-bottom: 0px;
  }

  .dropdown {
    > button {
      width: 300px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      span {
        display: flex;
        flex: auto;
        margin-left: 6px;
      }
    }

    .filters-container {
      min-width: 360px !important;
      padding: 6px 8px 0px 8px;

      .nav {
        .nav-item {
          .nav-link {
            border: none;
            border-bottom: 3px solid transparent;
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            flex-flow: column;
            height: 86px;

            img {
              max-width: 20px;
            }
          }

          &.active {
            .nav-link {
              color: #5664d2;
              border-bottom: 3px solid;
              border-bottom-color: #045cff;
            }
          }
        }
      }

      .navs-content-container {
        padding: 8px 0px;
        max-height: 320px;
        overflow: auto;

        .filter-item {
          margin: 0px;
          padding: 8px 16px 8px 16px;
        }
      }
    }

    .actions-container {
      padding: 8px 12px 8px 12px;
      border-top: 1px solid #00000020;
    }
  }
}

.history-log-details-modal {
  .detail-label,
  .detail-value {
    color: #8a8da3;
    font-weight: 500;
    font-size: 14px;

    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .detail-value {
    color: #1a2052;

    i {
      font-size: 20px;
    }

    &.bold {
      font-weight: 700;
    }

    &.light {
      color: #888aab;
    }
  }
}

.finding-details-content {
  .side-box {
    border-radius: 20px;
    border: 1px solid #5664d2;
    padding: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;

    &.ai-help-box {
      .image-box {
        img {
          max-width: 120px;
        }
      }
    }
  }

  .finding-content {
    .finding-content-title {
      display: flex;
      justify-content: space-between;
      align-items: start;

      h5 {
        margin: 0px;
        font-size: 18px;
        font-weight: 700;
        color: #000000;
      }
    }

    .finding-actions-container {
      display: flex;
      justify-content: end;
      align-items: center;
    }

    .finding-content-image {
      overflow: hidden;
      img {
        border-radius: 12px;
      }
    }

    .finding-content-body {
      line-height: 21px;
      font-weight: 400;
      font-size: 14px;
      color: #000000;
    }

    .finding-source-link {
      a {
        color: #4f4f4f;
        font-size: 10px;
        font-weight: 400;
      }
    }
  }
}

.overview-content {
  .overview-section-title {
    font-weight: 600;
    font-size: 16px;
    color: #1d1d1d;
  }

  .latest-finding-section {
    .card {
      border: 1px solid #bdbdbd;
      border-radius: 12px;
      padding: 16px 30px 16px 30px;

      img {
        border-radius: 14px;
      }

      .card-body {
        padding-left: 0px;
        padding-right: 0px;
        font-size: 14px;
        color: #1a1d30cc;
        font-weight: 400;

        .card-title {
          font-weight: 600;
          color: #000000;
          min-height: 70px;
          cursor: pointer;
        }

        small {
          font-size: 12px;
          &.category {
            color: #4f4f4f;
          }
          &.last-update {
            color: #828282;
          }
        }
      }
    }
  }

  .message-details-box {
    border: none;

    h5 {
      font-size: 16px !important;
      color: #1d1d1d;
    }
  }
}

.link-qr-code-modal {
  .modal-title {
    justify-content: center;
    text-align: center;
    font-size: 18px;
    color: #333333;
    font-weight: 700;
  }
}

.keyboard-modal-main-content {
  .modal-header {
    padding: 2rem 2rem 0.5rem 2rem;
    border: none;
    button {
      right: 1.25rem;
      top: 1rem;
      font-size: 12px;
    }
  }
  .modal-body {
    padding: 0rem 2rem 0.5rem 2rem;
    border: none;
    .selected-input {
      border-radius: 0.375rem;
      border: 1px solid #dee2e6;
      div {
        border: none;
      }
    }
  }

  .modal-footer {
    padding: 1rem 2rem 2rem 2rem;
    border: none;
    button {
      width: 58px;
      border-radius: 0.375rem;
      margin: 0;
    }
  }

  .modal-content {
    width: 648px;
  }
}

.manage-keywords-container {
  min-height: 100px;
  border: 1px solid #dee2e6;
  padding: 0.375rem;
  border-radius: 0.375rem;
  overflow: auto;
  margin-top: 0.5rem;
  background-color: #fff; // Adding background color to resemble a textarea
  &:focus {
    outline: none; // Removes the outline on focus to mimic a textarea
  }
}

.tag {
  display: inline-block;
  background-color: #e9ecef;
  border-radius: 4px;
  padding: 3px 6px 3px 6px;
  margin: 2px 4px;
  font-size: 14px;
  background-color: rgba(248, 249, 250, 1);
  cursor: pointer;
  border: 1px solid rgba(206, 212, 218, 1);

  .tag-close {
    margin-left: 5px;
    cursor: pointer;
  }

  &:hover {
    background-color: darken(
      #e9ecef,
      5%
    ); // Darkens the tag background on hover
  }
}


/* Flex container */
.news-container {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}

/* Image section */
.news-image {
  flex: 0 0 30%; /* 30% width */
  max-width: 30%;
}

/* Text section */
.news-text {
  flex: 0 0 70%; /* 70% width */
  max-width: 70%;
  padding-left: 15px; /* Space between image and text */
}

/* Responsive styles */
@media (max-width: 768px) {
  .news-container {
    flex-direction: column; /* Stack vertically on small screens */
  }

  .news-image,
  .news-text {
    flex: 0 0 100%; /* Full width */
    max-width: 100%;
    padding-left: 0; /* Remove padding */
  }

  .news-image {
    margin-bottom: 15px; /* Space between image and text */
  }
}
