.custom-table-filter{
  display: flex;
  justify-content: end;
  align-items: center;


  .dropdown-menu{
      left: auto !important;
      right: 0px !important;
      padding-bottom: 0px;
  }

  .dropdown{
      > button{
          width: 300px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          span{
              display: flex;
              flex: auto;
              margin-left: 6px;
          }
      }

      .filters-container{
          min-width: 360px !important;
          padding: 6px 8px 0px 8px;

          .nav{
              .nav-item{
                  .nav-link{
                      border: none;
                      border-bottom: 3px solid transparent;
                      display: flex;
                      justify-content: space-evenly;
                      align-items: center;
                      flex-flow: column;
                      height: 86px;

                      img{
                          max-width: 20px;
                      }
                  }

                  &.active{
                      .nav-link{
                          color: #5664D2;
                          border-bottom: 3px solid;
                          border-bottom-color: #045CFF;
                      }
                  }
              }
          }

          .navs-content-container{
              padding: 8px 0px;
              max-height: 350px;
              overflow: auto;

              .filter-item {
                  margin: 0px;
                  padding: 8px 16px 8px 16px;
              }
          }

          
      }

      .actions-container{
          padding: 8px 12px 8px 12px;
          border-top: 1px solid #00000020;
      }
  }
}

.custom-navigator{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.incident-download-button{
    height: 30px;
    color: #fff;
    background: #545B9A;
    border-radius: 8px;
    padding-top:0px ;
    padding-bottom:0px ;
}
.incident-Details-card{
    box-shadow: 0px 4px 4px 0px #00000040;
    padding: 20px;
    display: flex;
    justify-content: center;
    gap:16px;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    border-radius: 8px;


}
