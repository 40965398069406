@media (max-width: 767px) {
  .form-container {
    padding: 20px;
    font-size: 14px;
  }

  h5 {
    font-size: 16px;
  }

  p {
    font-size: 12px;
  }

  .form-label {
    font-size: 12px;
  }

  .mb-3 {
    margin-bottom: 10px;
  }
}
