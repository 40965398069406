.custom-pagination {
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	padding: 20px 30px;

	.pageNumberContainer {
		list-style: none;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-evenly;
		margin: 0;
		padding: 0;
	}

	.pageNumberContainer li {
		height: 44px;
		width: 44px;
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: center;
		color: #1a2052;
		border-radius: 8px;
		background-color: #f5f4f7;
		margin: 5px;
	}

	.pageNumberContainer li.active {
		color: #fff;
		background-color: #1a2052;
	}

	.pageNumberContainer li:last-child,
	.pageNumberContainer li:first-child {
		width: fit-content;
	}

	.pageNumberContainer li button {
		background-color: transparent;
		border: none;
		color: #000000;
		font-size: 1rem;
		cursor: pointer;
	}

	.pageNumberContainer li button:focus {
		outline: none;
	}

	.pageNumberContainer .next-prev-btn {
		width: 95px;
	}

	.drop-down-menu {
		min-width: 58px;
		border: 1px solid #e1e0e1;
		border-radius: 10px;
		.dropdown-item {
			&.selected {
				color: var(--bs-dropdown-link-hover-color) !important;
				text-decoration: none !important;
				background-color: var(--bs-dropdown-link-hover-bg) !important;
			}
		}
	}

	.drop-down-label {
		color: #1a2052;
	}
}
