.select-box-container-component {
	position: relative;
	width: 228px;
	height: 48px;
	color: #1a2052;
	margin-bottom: 14px;
	padding-left: 48px;
	border: 1px solid #fff;
	border-radius: 8px;
	background-color: #f9f8fa;
	position: relative;
	display: flex;
	align-items: center;

	.calender-icon {
		position: absolute;
		left: 16px;
		top: 12px;
		z-index: 99;
	}
	.down-icon {
		position: absolute;
		right: 16px;
		z-index: 999;
		top: 12px;
		cursor: pointer;
	}
	.select-box-label {
		margin: 0;
		font-weight: 500;
	}
	.option-box {
    box-shadow: 0 0 10px -1px #dadada;
		position: absolute;
		top: 58px;
		left: 0;
		background-color: #f9f8fa;
		padding: 10px;
		border-radius: 8px;
	}
}
