.list-suppliers{
    .toggle-supplier-flag-btn{
        &:hover{
            background: transparent;
        }
    }

    .custom-table-filter{
        .filters-container{
            min-width: 590px !important;
            @media screen and (max-width: 992px){
                min-width: auto !important;
            }
        }
    }
}