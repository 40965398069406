.page-gdpr-customization {
    background-color: #ffffff;
    border-radius: 12px;
    padding: 32px;
    gap: 54px;

    .dropzone-previews {
        display: inline-block;
        &.primary-background{
            background-color: var(--color-primary);
            box-shadow: 0px 0px 8px 2px var(--color-primary);
        }

        img{
            max-width: 100px;
            border: 1px solid #eee;
            box-shadow: 0px 0px 8px 2px #e4e7eb;
            padding: 14px
        }
    }
  
    .header-section {
        display: flex;
        justify-content: space-between;
    }
  
    .customization-image-col {
        width: Hug (40px) px;
        max-width: 40px;
        max-height: 40px;
        height: Hug (40px) px;
        padding: 0px 0px 0px 0px;
        gap: 10px;
        border-radius: 100px 0px 0px 0px;
        flex: 0 0 2%;
        img {
            width: 100%;
        }
    }
  
    .customization-accordion-col {
        flex: 0 0 98%;
    }

    .customization-accordion{
        box-shadow: 0px 0.5px 6px 0px rgba(0, 0, 0, 0.25);
    }
}