.page-tpdd-customization {
    background-color: #ffffff;
    border-radius: 12px;
    padding: 32px;
    gap: 54px;

    .dropzone-previews {
        display: inline-block;
        &.primary-background{
            background-color: var(--color-primary);
            box-shadow: 0px 0px 8px 2px var(--color-primary);
        }

        img{
            max-width: 100px;
            border: 1px solid #eee;
            box-shadow: 0px 0px 8px 2px #e4e7eb;
            padding: 14px
        }
    }

    .header-section {
        display: flex;
        justify-content: space-between;
    }

    .customization-image-col {
        width: Hug (40px) px;
        max-width: 40px;
        max-height: 40px;
        height: Hug (40px) px;
        padding: 0px 0px 0px 0px;
        gap: 10px;
        border-radius: 100px 0px 0px 0px;
        // opacity: 0;
        flex: 0 0 2%;
        img {
        width: 100%;
        }
    }

    .customization-accordion-col {
        flex: 0 0 98%;
    }
}

.section-info-button {
    color: #828282;
    font-weight: normal;
    margin-left: 5px;
    cursor: pointer;
    width: 20px;
    height: 20px;
    gap: 0px;
}

.cusom-accordion-item {
    width: Fill (1, 286px) px;
    height: Hug (964.72px) px;
    gap: 16px;
    border-radius: 8px;
    background: #f1f5f733;
    box-shadow: 0px 0.5px 6px 0px #00000040;
}

.custom-accordion-header {
    width: Fill (1, 286px) px;
    height: Hug (40px) px;
    background-color: white !important;
    gap: 0px;
    border-radius: 8px;
}

.custom-accordion-header .accordion-button {
    width: Fill (1, 286px) px;
    background-color: white !important;
    height: Hug (40px) px;
    gap: 0px;
    border-radius: 8px;
    border: none !important;
}

.custom-div-header {
    padding: 0px !important;
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    line-height: 19.36px;
    text-align: left;
    color: #11142a;
    border: none !important;
}

.langButton {
    width: Fixed (126px) px;
    height: Hug (51px) px;
    padding: 16px 32px 16px 32px;
    gap: 4px;
    border-radius: 4px;
    border: 1px 0px 0px 0px;
    background: #f8f9fa;
    border: 1px solid #ced4da;
}
