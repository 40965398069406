.module__tpdd-thirdparties-selection{
    .expand-btn{
        display: inline-block;
        margin-left: auto;
        margin-right: 0px;
        border-radius: 0.25rem;
        font-size: 11px;
        line-height: 20px;
        padding: 0 10px;
        cursor: pointer;
    }

    .items-container{
        display: flex;
        flex-direction: column;

        > div{
            border: 1px solid #dadada;
            padding: 10px;
            border-radius: 6px;

            .list-group{
                overflow-y: scroll;
                transition: all ease 0.3s;

                .delete-btn{
                    float: right;
                    cursor: pointer;
                }
            }

            .empty-list-message{
                line-height: 43px;
                overflow-y: scroll;
                transition: all ease 0.3s;
            }
        }
    }
}