.redCircle {
  width: 12px;
  height: 12px;
  background: rgba(191, 23, 66, 1);
  border-radius: 50%;
  display: inline-block;
}

.redCircle + h6, .redCircle + h4 {
  display: inline-block;
  margin-left: 1%;
}

.tag {
  background-color: rgba(248, 249, 250, 1);
  width: Hug (61px) px;
  height: Hug (17px) px;
  padding: 1px 8px 1px 8px;
  gap: 4px;
  border-radius: 4px 0px 0px 0px;
  border: 1px 0px 0px 0px;
  border: 1px sold rgba(206, 212, 218, 1);
}

.flag {
  background-color: #f5f4f7;
  padding: 6px;
  border-radius: 10px;
  color: #1a2052;
  cursor: pointer;
}

.selectedFlag {
  background-color: #fc5959;
  padding: 6px;
  border-radius: 10px;
  color: #f5f4f7;
  height: max-content;
  cursor: pointer;
}

.selected-news {
  background-color: rgba(203, 209, 252, 0.5);
}
