.keyboard-modal-main-content-data-request{
    .modal-header{
        padding: 2rem 2rem 0.5rem 2rem;
        border: none;

        h5{
            color: black;
            font-size: 16px;
            font-weight: 700;
        }

        button{
            right: 1.25rem;
            top: 1.5rem;
            font-size: 12px;
        }

    }
    .modal-body{
        padding: 0rem 2rem 0.5rem 2rem;
        border: none;
        .selected-input {
            border-radius: 0.375rem;
            border: 1px solid #DEE2E6;
            div{
            border:none;
            }
        }
    }

    .modal-footer{
        padding: 1rem 2rem 2rem 2rem;
        border: none;
        button{
            width: 58px;
            border-radius: 0.375rem;
            margin: 0;
        }
    }

    .modal-content{
        width: 100%;
    }

    .main-div{
        display: flex;
        flex-direction: column;
        gap: 32px;
        margin-top: 24px;
        margin-bottom: 24px;
    }
    label{
        color: black;
    }

    span{
        color: black;
        font-size: 14px;
        font-weight: 400;
    }


}