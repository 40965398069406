.contractors-list{
    padding: 0px 16px 0px 16px;
    border-radius: 12px;

    input[type="text"]{
        padding: 9px 0px 9px 17px;
    }

    button{
        padding: 7px 14px 7px 14px;
    }
    .table{
        border: #fff;
    }
}

.section-info-button{
    color:#828282;
    font-weight: normal;
    margin-left: 5px;
    cursor: pointer;
}