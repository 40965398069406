.score{
    display: flex;
    justify-content: start;
    align-items: center;

    .items{
        display: flex;
        width: 112px;
        height: 22px;
        
        .score-item{
            background-color: #CBD1FC;
            height: 100%;
            border-radius: 8px;
            display: flex;
            flex-flow: column wrap;
            flex: 1 1;
            margin: 0px 2px;

            &.fill{
                background-color: #6775E4;
            }
        }
    }

    .numeric-value{
        font-size: 10px;
        font-weight: 400;
        color: #414FBD;
    }
}