.report-details-box{
    min-height: 295px;
    font-weight: 400;
    background-color: #5664D21A;
    background-image: url('../../../images/details-background.svg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 8px;
    font-size: 16px;
    border: 1px solid #5664D2;
    display: flex;
    justify-content: center;
    align-items: center;

    .report-details-rows-container {
        max-width: 1366px;
        width: 100%;
        display: grid;
        grid-template-columns: repeat(8, 1fr);
        gap: 10px;
        padding: 20px;

        .report-details-row{
            grid-column: 1 / -1;
            display: grid;
            grid-template-columns: repeat(3, 1fr); 
            gap: 10px;

            &:first-child{
                grid-template-columns: repeat(8, 1fr);

                .report-details-col{
                    text-align: left;
                    align-items: flex-start;
                }
            }

            .report-details-col {
                padding: 10px;
                text-align: center;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                flex-flow: column;

                .detail-title{
                    margin-bottom: 14px;
                    font-weight: 600;
                }

                .detail-value{
                    img{
                        width: 40px;
                        height: 40px;
                        border-radius: 50%;
                    }

                    .supplier-flagging{
                        border: 0px;
                        text-align: left;
                        padding: 0px;
                        &:hover{
                            background-color: transparent;
                        }

                        img{
                            width: auto;
                            height: auto;
                            border-radius: 0px;
                        }

                        .info{
                            margin-top: 3px;
                            display: block;
                            font-size: 12px;
                            color: #4F4F4F;
                        }
                    }
                }
            }
        }

        @media (max-width: 1366px) {
            &.report-details-rows-container {
                grid-template-columns: repeat(4, 1fr);

                .report-details-row{
                    grid-template-columns: repeat(3, 1fr);

                    &:first-child{
                        grid-template-columns: repeat(4, 1fr);
                    }
                }
            }
        }

        @media (max-width: 992px) {
            &.report-details-rows-container {
                grid-template-columns: repeat(3, 1fr);

                .report-details-row{
                    grid-template-columns: repeat(3, 1fr);

                    &:first-child{
                        grid-template-columns: repeat(3, 1fr);
                    }
                }
            }
        }

        @media (max-width: 768px) {
            &.report-details-rows-container {
                grid-template-columns: repeat(2, 1fr);

                .report-details-row{
                    grid-template-columns: repeat(2, 1fr);

                    &:first-child{
                        grid-template-columns: repeat(2, 1fr);
                    }
                }
            }
        }
    }

    &.single-line-view {
        min-height: auto;
        max-height: 100px;

        .report-details-rows-container{
            .report-details-row{
                grid-template-columns: repeat(3, 1fr);
        
                &:last-child{
                    display: none;
                }
            }
        }
    }

    &.tp-view{
        min-height: auto;

        .report-details-rows-container{
            .report-details-row{
                grid-template-columns: repeat(7, 1fr);
    
                &:last-child{
                    display: none;
                }
            }
        }
    }
}

.loading-section{
    background-color: #eeeffa;
    border-radius: 8px;
}