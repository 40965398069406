/* Import the Bootstrap CSS if you haven't already */
@import '~bootstrap/dist/css/bootstrap.css';

/* Define a SCSS file for your RiskMatrix component */
.dt-matrix {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;

  .dt-matrix-x-axis-label {
    @media (max-width: 768px) {
        //     /* Adjust cell size for smaller screens */
            width: 80%; /* Adjust the width for smaller screens */
        //     // height: 1000px; /* Set the same height as the width for square cells */
          }
  }

  .dt-matrix-y-axis-label {
    @media (max-width: 768px) {
        // height: 200px !important;
        // background-color: red;
        // color: yellow;
        height: 50%;
        width: 200%;
          }
  }

//   .dt-matrix-info.dt-matrix-x-axis{
//     @media (max-width: 768px) {
//         /* Adjust cell size for smaller screens */
//         width: 200px; /* Adjust the width for smaller screens */
//         // height: 1000px; /* Set the same height as the width for square cells */
//       }
//   }
  /* Set a fixed width and height for each cell */
  .dt-matrix-column {
    width: 40px; /* Adjust the width as needed */
    height: 40px; /* Set the same height as the width for square cells */

    @media (max-width: 768px) {
      /* Adjust cell size for smaller screens */
      width: 30px; /* Adjust the width for smaller screens */
      height: 30px; /* Set the same height as the width for square cells */
    }
  }

  caption {
    /* Caption styles */

    @media (max-width: 768px) {
      /* Adjust caption styles for smaller screens */
    }
  }

  .dt-matrix-y-axis-label {
    /* Y-axis label styles */

    @media (max-width: 768px) {
      /* Adjust Y-axis label styles for smaller screens */
    }
  }

//   .dt-matrix-info, .dt-matrix-x-axis {
//     color: green;
//     width: 500px;
//   }

  .dt-matrix-x-axis-title, .dt-matrix-x-axis-value {
    @media (max-width: 768px) {
        /* Adjust Y-axis and X-axis styles for smaller screens */
        // color: red;
        width: 50px;
      }
    
  }

  .dt-matrix-y-axis,
  .dt-matrix-x-axis {
    /* Y-axis and X-axis styles */

    @media (max-width: 768px) {
      /* Adjust Y-axis and X-axis styles for smaller screens */
    }
  }

  .dt-matrix-risk-bullet {
    /* Risk bullet styles */

    @media (max-width: 768px) {
      /* Adjust risk bullet styles for smaller screens */
    }
  }

  /* Add more specific styles and media queries as needed */
  .dt-matrix-risk-bullet-container {
    display: inline-flex;
    align-items: center;
    
  }
  
  .dt-matrix-risk-bullet {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: blue;
    // color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-right: 5px; // Space between bullets
    position: relative;
    z-index: 1;
  }

  
  .dt-matrix-more-bullets-container {
    position: relative;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
  }
  
  .dt-matrix-more-bullets {
    display: inline-block;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: white; // To distinguish it from the risk bullets
    // color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .dt-matrix-all-risks {
    display: none;
    position: absolute;
    top: 25px;
    left: 0; // Align with the start of the container
    // white-space: nowrap; // Ensure horizontal alignment
    // padding-left: 30px; // Offset from the "+" button
    flex-direction: column; // This makes the items align vertically
    padding-top: 5px; 
  }
  
  .dt-matrix-more-bullets-container:hover .dt-matrix-all-risks {
    display: flex;
  }
  
  .dt-matrix-more-bullets-container:hover .dt-matrix-more-bullets {
    display: none; // Hide the "+" on hover
  }
  



  .dt-matrix-more-bullets-container {
    position: relative;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
  
    .dt-matrix-all-risks {
      visibility: hidden; // Initially hidden
      opacity: 0; // Start fully transparent
      display: flex; // Use flex to manage layout
      flex-direction: column; // Align items vertically
      position: absolute;
      top: 25px;
      left: 0;
      transition: visibility 0s linear 0.5s, opacity 0.5s linear; // Transition for visibility and opacity
      // background-color: rgba($color: #FFFFFF, $alpha: 0.5); // Optional: background color to make it more readable
      border-radius: 5px; // Optional: for styled appearance
      padding: 10px; // Optional: for internal spacing
      // box-shadow: 0 2px 4px rgba(0,0,0,0.1); // Optional: for a subtle shadow effect
      z-index: 2;
    }
  
    &:hover .dt-matrix-all-risks {
      visibility: visible; // Make it visible on hover
      opacity: 1; // Fully opaque
      transition-delay: 0s; // Apply transition without delay on hover
      z-index: 10;
    }
  }
  
  .dt-matrix-more-bullets-container:hover .dt-matrix-more-bullets {
    display: inline-flex; // Ensure it remains visible during hover
  }
  
  // When not hovered, the container will start the transition to hide after a delay, but hovering back over it will cancel this and show it immediately.
  
}



