.dropzone{
    &.tpdd{
        min-height: auto;
        border: none;
        background: #F8F9FA;

        .dz-message{
            margin: 0px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 4px 4px;
            

            > *{
                background: #F8F9FA;
                font-size: 16px;
                color: #6C757D;
            }
        }

        .select-files-btn {
            background-color: #E4E7EB;
            border: 1px solid #E4E7EB;
            color: #212529;
        }
    }
}

.dropzone-previews {
    &.tpdd{
        .card:first-child{
            border-top: 1px solid #DEE2E6;
        }
        .card{
            border-bottom: 1px solid #DEE2E6;
            border-radius: 0px;

            .file-name{
                color: #212529;
                font-size: 16px;
            }

            .file-size{
                color: #6C757D;
                font-size: 11px;
            }

            .upload-details-container{
                margin: 6px 0px 0px 0px;
                display: flex;
                justify-content: center;
                align-items: start;
                flex-flow: column;
            }

            .actions-container{
                text-align: right;
                button{
                    font-weight: bold;
                }
            }

            .upload-status-message{
                font-size: 11px;

                &.succeed{
                    color: #198754;
                }

                &.failed{
                    color: #DC3545;
                }

            }

            .progress{
                height: 2px;
                bottom: 0px;

                &::before{
                    background-color: transparent !important;
                }

                .progress-bar{
                    background-color: #0DCAF0 !important;
                }
            }
        }
    }
}