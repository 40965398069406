html[data-module='tp']{
    @import "./variables/index";

    @import "./components/index";

    @import "./pages/admin/index";
    
    @import "./pages/thirdparty/index";    

    body{
        background-color    :   #f3f3f3;
        font-size           :   font-sizes(common, base);
        font-weight         :   400;
    }

    .main-content{
        overflow: visible;
    }

    .topnav{
        margin-top: 0px;
    }
    
    @media (max-width: 992px){
        .topnav {
            margin-top: 70px;
        }
    }

    iframe {
        display: none !important;
    }

    .ellipsis {
        display: block;
        display: -webkit-box;
        max-width: 100%;
        margin: 0 auto;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    
        &.l-1{
            -webkit-line-clamp: 1;
        }
    
        &.l-2{
            -webkit-line-clamp: 2;
        }
    
        &.l-3{
            -webkit-line-clamp: 3;
        }
    }
}