.custom-accordion{
    .accordion-item{
        margin-bottom: 16px;
        border: none;
        border-radius: 8px !important;
        overflow: hidden;
        box-shadow: 0px 4px 4px 0px #00000040;
        position: relative;

        .form-control {
            background: transparent;
        }

        label {
            color : #000000;
            font-size: 16px;
            font-weight: 600;
        }

        &.active{
            border: 1px solid #4F4F4F;

            .accordion-header button{
                background: #FFFFFF;
                box-shadow: none;
            }
        }

        .accordion-collapse {
            background-color: #FFFFFF;
        }

        .accordion-header{
            background: #FFFFFF;
            
            button{
                color: #000;
                font-weight: bold;
                padding: 16px;
                height: 92px;
                border-radius: 8px;

                > * {
                    position: relative;
                    z-index: 1;
                    background: transparent;
                    color : #000000;
                    font-size: 16px;
                    font-weight: 600;
                }

                img{
                    margin-right: 12px;
                }

                .edit-general-infos-btn{
                    font-size: 26px;
                    margin-left: 6px;
                    font-weight: normal;
                }
                
            }
        }

        .avatar-group{
            .avatar-group-item{
                width: 40px;
            }
        }
    }
}