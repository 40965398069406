.kscrollbar {
    transition: background-color .8s;
}

/* width */
.kscrollbar::-webkit-scrollbar {
    width: 7px;
}

/* Track */
.kscrollbar::-webkit-scrollbar-track {
    background: transparent;
}

/* Handle */
.kscrollbar::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 30px;
    transition: all .3s;
}

/* Handle on hover */
.kscrollbar::-webkit-scrollbar-thumb:hover {
    background: #e1e1e1;
}

.kscrollbar:hover::-webkit-scrollbar-thumb {
    background-color: #e1e1e1;
}