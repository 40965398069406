.rdw-editor-wrapper{
    &:not(.editor-default){
        border: 1px solid color(black, 4);
        border-radius: 12px;
        padding: 16px;

        .rdw-editor-toolbar{
            border:none;
        }

        .rdw-editor-main{
            border: none;
        }
    }
}