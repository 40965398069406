svg {
	touch-action: none;
}

.jvectormap-container {
	width: 100%;
	height: 100%;
	position: relative;
	overflow: hidden;
	touch-action: none;
}

.jvectormap-tip {
	position: absolute;
	display: none;
	border: solid 1px #cdcdcd;
	border-radius: 3px;
	background: #292929;
	color: white;
	font-family: sans-serif, Verdana;
	font-size: smaller;
	padding: 3px;
}

.jvectormap-legend-title {
	font-weight: bold;
	font-size: 14px;
	text-align: center;
}

.jvectormap-legend-cnt {
	position: absolute;
}

.jvectormap-legend-cnt-h {
	bottom: 0;
	right: 0;
}

.jvectormap-legend-cnt-v {
	top: 0;
	right: 0;
}

.jvectormap-legend {
	background: black;
	color: white;
	border-radius: 3px;
}

.jvectormap-legend-cnt-h .jvectormap-legend {
	float: left;
	margin: 0 10px 10px 0;
	padding: 3px 3px 1px 3px;
}

.jvectormap-legend-cnt-h .jvectormap-legend .jvectormap-legend-tick {
	float: left;
}

.jvectormap-legend-cnt-v .jvectormap-legend {
	margin: 10px 10px 0 0;
	padding: 3px;
}

.jvectormap-legend-cnt-h .jvectormap-legend-tick {
	width: 40px;
}

.jvectormap-legend-cnt-h .jvectormap-legend-tick-sample {
	height: 15px;
}

.jvectormap-legend-cnt-v .jvectormap-legend-tick-sample {
	height: 20px;
	width: 20px;
	display: inline-block;
	vertical-align: middle;
}

.jvectormap-legend-tick-text {
	font-size: 12px;
}

.jvectormap-legend-cnt-h .jvectormap-legend-tick-text {
	text-align: center;
}

.jvectormap-legend-cnt-v .jvectormap-legend-tick-text {
	display: inline-block;
	vertical-align: middle;
	line-height: 20px;
	padding-left: 3px;
}

.map-container {
	margin-left: 0px;
	margin-right: 0px;
	margin-bottom: 30px;
	/* overflow: scroll; */
	background-color: white;
}

.map-header {
	font-size: 1.5rem;
	font-weight: 400;
	line-height: 2rem;
	padding-left: 25px;
	padding-right: 25px;
	margin-bottom: 25px;
}

.jvectormap-container {
	height: 550px;
}

.map {
	margin: 0 auto;
}

.jvectormap-zoomin,
.jvectormap-zoomout {
	display: none;
}

.svg {
	height: 550px;
	width: 100%;
	overflow: scroll;
}

.legend-title {
	margin-bottom: 3px;
	margin-top: -45px;
	margin-left: 20px;
	font-size: 1em;
	letter-spacing: 0.025em;
}

.legend {
	width: 50%;
	max-width: 274px;
	/* overflow: scroll; */
	margin-left: 20px;
	margin-bottom: 15px;
	display: grid;
	grid-gap: 1.25%;
	grid-template-columns: repeat(5, 1fr);
	grid-template-rows: repeat(2, 1fr);
}

.level-1 {
	background-color: #e5d1f9;
	padding-top: 13%;
}

.level-2 {
	background-color: #a667e5;
}

.level-3 {
	background-color: #843dcc;
}

.level-4 {
	background-color: #661ab2;
}

.level-5 {
	background-color: #5606a5;
}

.scale {
	font-size: 0.7em;
	text-align: center;
	color: #5f6368;
}
