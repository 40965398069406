.tpdd-pagination-style-1{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-flow: row-reverse;
    padding: 8px 45px;

    @media only screen and (max-width: 768px){
        padding: 0px;
    }

    .pagination{
        margin: 0px;
        display: flex;
        justify-content: center;
        align-items: center;

        .page-item{
            margin: 0px 8px;
            a{
                padding: 6px 10px 6px 10px;
                background-color: color(grey, 5);
                color: color(primary, 500);
                font-size: font-sizes(common, medium);
                font-weight: 500;
                border: none;
                border-radius: 8px;
                min-width: 44px;
                height: 44px;
                text-align: center;
                display: flex;
                justify-content: center;
                align-items: center;

                @media only screen and (max-width: 768px){
                    padding: 2px 4px 2px 4px;
                    font-size: font-sizes(common, small);
                    height: auto;
                }

                span{
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    i{
                        font-size: 18px;
                    }
                }
            }

            &.active{
                a{
                    background-color: color(primary, 500);
                    color: color(white, 1);
                }
            }
        }
    }

    .react-bs-table-sizePerPage-dropdown{
        margin-right: 2px;

        button{
            padding: 6px 10px 6px 10px;
            background-color: color(grey, 5);
            color: color(primary, 500) !important;
            font-size: font-sizes(common, medium);
            font-weight: 500;
            border-radius: 8px;
            border: none;
            min-width: 76px;
            height: 44px;

            @media only screen and (max-width: 768px){
                padding: 6px 4px 6px 4px;
                font-size: font-sizes(common, small);
                height: auto;
            }
        }

        .dropdown-menu {
            .dropdown-item a{
                display: block;
                color: #1A2052;
            }
        }
    }

    .react-bootstrap-table-pagination-total{
        align-self: flex-end;
        font-size: font-sizes(common, medium);
        color: color(primary, 500);
        font-weight: 500;

        @media only screen and (max-width: 768px){
            font-size: font-sizes(common, small);
        }
    }
}