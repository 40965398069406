.custom-navigator{
    display: flex;
    justify-content: space-between;
    align-items: center;

    .navigator-breadcrumbs{

        .breadcrumb-item{
            display: flex;
            justify-content: center;
            align-items: center;
            padding-left: 0.3rem !important;

            a{
                font-size: font-sizes(common, small);
                color: color(grey, 3);
                font-weight: 400;
            }

            &::before{
                color : color(grey, 3);
                padding: 0px 0.3rem 0px 0px;
                font-size: 1.3rem;
            }

            &:last-child{
                a{
                    color: color(black, 1);
                    font-weight: 600;
                }
            }
        }
    }

    .navigator-actions{
        button{
            padding: 2px 4px 2px 4px;
            border: 1px solid color(grey, 2);
            font-weight: 400 !important;
            color: color(grey, 2) !important;
            font-size: font-sizes(common, small) !important;

            &:hover{
                color: color(white, 1) !important;
            }
        }
    }
}