// Inter
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url("../../../fonts/inter/Inter-Thin.woff2") format("woff2"),
    url("../../../fonts/inter/Inter-Thin.woff") format("woff");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url("../../../fonts/inter/Inter-ExtraLight.woff2") format("woff2"),
    url("../../../fonts/inter/Inter-ExtraLight.woff") format("woff");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("../../../fonts/inter/Inter-Light.woff2") format("woff2"),
    url("../../../fonts/inter/Inter-Light.woff") format("woff");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../../../fonts/inter/Inter-Regular.woff2") format("woff2"),
    url("../../../fonts/inter/Inter-Regular.woff") format("woff");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("../../../fonts/inter/Inter-Medium.woff2") format("woff2"),
    url("../../../fonts/inter/Inter-Medium.woff") format("woff");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("../../../fonts/inter/Inter-SemiBold.woff2") format("woff2"),
    url("../../../fonts/inter/Inter-SemiBold.woff") format("woff");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("../../../fonts/inter/Inter-Bold.woff2") format("woff2"),
    url("../../../fonts/inter/Inter-Bold.woff") format("woff");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url("../../../fonts/inter/Inter-ExtraBold.woff2") format("woff2"),
    url("../../../fonts/inter/Inter-ExtraBold.woff") format("woff");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url("../../../fonts/inter/Inter-Black.woff2") format("woff2"),
    url("../../../fonts/inter/Inter-Black.woff") format("woff");
}

// Nunito
@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("../../../fonts/nunito/Nunito-Light.woff2") format("woff2"),
    url("../../../fonts/nunito/Nunito-Light.woff") format("woff");
}

@font-face {
  font-family: "Nunito";
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url("../../../fonts/nunito/Nunito-LightItalic.woff2") format("woff2"),
    url("../../../fonts/nunito/Nunito-LightItalic.woff") format("woff");
}

@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../../../fonts/nunito/Nunito-Regular.woff2") format("woff2"),
    url("../../../fonts/nunito/Nunito-Regular.woff") format("woff");
}

@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("../../../fonts/nunito/Nunito-Medium.woff2") format("woff2"),
    url("../../../fonts/nunito/Nunito-Medium.woff") format("woff");
}

@font-face {
  font-family: "Nunito";
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url("../../../fonts/nunito/Nunito-MediumItalic.woff2") format("woff2"),
    url("../../../fonts/nunito/Nunito-MediumItalic.woff") format("woff");
}

@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("../../../fonts/nunito/Nunito-SemiBold.woff2") format("woff2"),
    url("../../../fonts/nunito/Nunito-SemiBold.woff") format("woff");
}

@font-face {
  font-family: "Nunito";
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url("../../../fonts/nunito/Nunito-SemiBoldItalic.woff2") format("woff2"),
    url("../../../fonts/nunito/Nunito-SemiBoldItalic.woff") format("woff");
}

@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("../../../fonts/nunito/Nunito-Bold.woff2") format("woff2"),
    url("../../../fonts/nunito/Nunito-Bold.woff") format("woff");
}

@font-face {
  font-family: "Nunito";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url("../../../fonts/nunito/Nunito-BoldItalic.woff2") format("woff2"),
    url("../../../fonts/nunito/Nunito-BoldItalic.woff") format("woff");
}

@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url("../../../fonts/nunito/Nunito-ExtraBold.woff2") format("woff2"),
    url("../../../fonts/nunito/Nunito-ExtraBold.woff") format("woff");
}

@font-face {
  font-family: "Nunito";
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url("../../../fonts/nunito/Nunito-ExtraBoldItalic.woff2") format("woff2"),
    url("../../../fonts/nunito/Nunito-ExtraBold.woff") format("woff");
}

@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url("../../../fonts/nunito/Nunito-Black.woff2") format("woff2"),
    url("../../../fonts/nunito/Nunito-Black.woff") format("woff");
}

@font-face {
  font-family: "Nunito";
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url("../../../fonts/nunito/Nunito-BlackItalic.woff2") format("woff2"),
    url("../../../fonts/nunito/Nunito-BlackItalic.woff") format("woff");
}

// Raleway
@font-face {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url("../../../fonts/raleway/Raleway-Thin.woff2") format("woff2"),
    url("../../../fonts/raleway/Raleway-Thin.woff") format("woff");
}

@font-face {
  font-family: "Raleway";
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url("../../../fonts/raleway/Raleway-ThinItalic.woff2") format("woff2"),
    url("../../../fonts/raleway/Raleway-ThinItalic.woff") format("woff");
}

@font-face {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url("../../../fonts/raleway/Raleway-ExtraLight.woff2") format("woff2"),
    url("../../../fonts/raleway/Raleway-ExtraLight.woff") format("woff");
}

@font-face {
  font-family: "Raleway";
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url("../../../fonts/raleway/Raleway-ExtraLightItalic.woff2")
      format("woff2"),
    url("../../../fonts/raleway/Raleway-ExtraLightItalic.woff") format("woff");
}

@font-face {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("../../../fonts/raleway/Raleway-Light.woff2") format("woff2"),
    url("../../../fonts/raleway/Raleway-Light.woff") format("woff");
}

@font-face {
  font-family: "Raleway";
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url("../../../fonts/raleway/Raleway-LightItalic.woff2") format("woff2"),
    url("../../../fonts/raleway/Raleway-LightItalic.woff") format("woff");
}

@font-face {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../../../fonts/raleway/Raleway-Regular.woff2") format("woff2"),
    url("../../../fonts/raleway/Raleway-Regular.woff") format("woff");
}

@font-face {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("../../../fonts/raleway/Raleway-Medium.woff2") format("woff2"),
    url("../../../fonts/raleway/Raleway-Medium.woff") format("woff");
}

@font-face {
  font-family: "Raleway";
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url("../../../fonts/raleway/Raleway-MediumItalic.woff2") format("woff2"),
    url("../../../fonts/raleway/Raleway-MediumItalic.woff") format("woff");
}

@font-face {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("../../../fonts/raleway/Raleway-SemiBold.woff2") format("woff2"),
    url("../../../fonts/raleway/Raleway-SemiBold.woff") format("woff");
}

@font-face {
  font-family: "Raleway";
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url("../../../fonts/raleway/Raleway-SemiBoldItalic.woff2")
      format("woff2"),
    url("../../../fonts/raleway/Raleway-SemiBoldItalic.woff") format("woff");
}

@font-face {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("../../../fonts/raleway/Raleway-Bold.woff2") format("woff2"),
    url("../../../fonts/raleway/Raleway-Bold.woff") format("woff");
}

@font-face {
  font-family: "Raleway";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url("../../../fonts/raleway/Raleway-BoldItalic.woff2") format("woff2"),
    url("../../../fonts/raleway/Raleway-BoldItalic.woff") format("woff");
}

@font-face {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url("../../../fonts/raleway/Raleway-ExtraBold.woff2") format("woff2"),
    url("../../../fonts/raleway/Raleway-ExtraBold.woff") format("woff");
}

@font-face {
  font-family: "Raleway";
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url("../../../fonts/raleway/Raleway-ExtraBoldItalic.woff2")
      format("woff2"),
    url("../../../fonts/raleway/Raleway-ExtraBoldItalic.woff") format("woff");
}

@font-face {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url("../../../fonts/raleway/Raleway-Black.woff2") format("woff2"),
    url("../../../fonts/raleway/Raleway-Black.woff") format("woff");
}

@font-face {
  font-family: "Raleway";
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url("../../../fonts/raleway/Raleway-BlackItalic.woff2") format("woff2"),
    url("../../../fonts/raleway/Raleway-BlackItalic.woff") format("woff");
}

// Roboto Mono
@font-face {
  font-family: "Roboto Mono";
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url("../../../fonts/roboto-mono/RobotoMono-Thin.woff2") format("woff2"),
    url("../../../fonts/roboto-mono/RobotoMono-Thin.woff") format("woff");
}

@font-face {
  font-family: "Roboto Mono";
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url("../../../fonts/roboto-mono/RobotoMono-ThinItalic.woff2")
      format("woff2"),
    url("../../../fonts/roboto-mono/RobotoMono-ThinItalic.woff") format("woff");
}

@font-face {
  font-family: "Roboto Mono";
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url("../../../fonts/roboto-mono/RobotoMono-ExtraLight.woff")
      format("woff2"),
    url("../../../fonts/roboto-mono/RobotoMono-ExtraLight.woff") format("woff");
}

@font-face {
  font-family: "Roboto Mono";
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url("../../../fonts/roboto-mono/RobotoMono-ExtraLightItalic.woff2")
      format("woff2"),
    url("../../../fonts/roboto-mono/RobotoMono-ExtraLightItalic.woff")
      format("woff");
}

@font-face {
  font-family: "Roboto Mono";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("../../../fonts/roboto-mono/RobotoMono-Light.woff") format("woff2"),
    url("../../../fonts/roboto-mono/RobotoMono-Light.woff") format("woff");
}

@font-face {
  font-family: "Roboto Mono";
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url("../../../fonts/roboto-mono/RobotoMono-LightItalic.woff2")
      format("woff2"),
    url("../../../fonts/roboto-mono/RobotoMono-LightItalic.woff") format("woff");
}

@font-face {
  font-family: "Roboto Mono";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../../../fonts/roboto-mono/RobotoMono-Regular.woff2")
      format("woff2"),
    url("../../../fonts/roboto-mono/RobotoMono-Regular.woff") format("woff");
}

@font-face {
  font-family: "Roboto Mono";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("../../../fonts/roboto-mono/RobotoMono-Medium.woff2") format("woff2"),
    url("../../../fonts/roboto-mono/RobotoMono-Medium.woff") format("woff");
}

@font-face {
  font-family: "Roboto Mono";
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url("../../../fonts/roboto-mono/RobotoMono-MediumItalic.woff2")
      format("woff2"),
    url("../../../fonts/roboto-mono/RobotoMono-MediumItalic.woff")
      format("woff");
}

@font-face {
  font-family: "Roboto Mono";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("../../../fonts/roboto-mono/RobotoMono-SemiBold.woff2")
      format("woff2"),
    url("../../../fonts/roboto-mono/RobotoMono-SemiBold.woff") format("woff");
}

@font-face {
  font-family: "Roboto Mono";
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url("../../../fonts/roboto-mono/RobotoMono-SemiBoldItalic.woff2")
      format("woff2"),
    url("../../../fonts/roboto-mono/RobotoMono-SemiBoldItalic.woff")
      format("woff");
}

@font-face {
  font-family: "Roboto Mono";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("../../../fonts/roboto-mono/RobotoMono-Bold.woff2") format("woff2"),
    url("../../../fonts/roboto-mono/RobotoMono-Bold.woff") format("woff");
}

@font-face {
  font-family: "Roboto Mono";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url("../../../fonts/roboto-mono/RobotoMono-BoldItalic.woff2")
      format("woff2"),
    url("../../../fonts/roboto-mono/RobotoMono-BoldItalic.woff") format("woff");
}

// Noto Serif Toto
@font-face {
  font-family: "Noto Serif Toto";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../../../fonts/noto-serif-toto/NotoSerifToto-Regular.woff2")
      format("woff2"),
    url("../../../fonts/noto-serif-toto/NotoSerifToto-Regular.woff")
      format("woff");
}

@font-face {
  font-family: "Noto Serif Toto";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("../../../fonts/noto-serif-toto/NotoSerifToto-Medium.woff2")
      format("woff2"),
    url("../../../fonts/noto-serif-toto/NotoSerifToto-Medium.woff")
      format("woff");
}

@font-face {
  font-family: "Noto Serif Toto";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("../../../fonts/noto-serif-toto/NotoSerifToto-SemiBold.woff2")
      format("woff2"),
    url("../../../fonts/noto-serif-toto/NotoSerifToto-SemiBold.woff")
      format("woff");
}

@font-face {
  font-family: "Noto Serif Toto";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("../../../fonts/noto-serif-toto/NotoSerifToto-Bold.woff2")
      format("woff2"),
    url("../../../fonts/noto-serif-toto/NotoSerifToto-Bold.woff") format("woff");
}

// NotoSansCherokee
@font-face {
  font-family: "Noto Sans Cherokee";
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url("../../../fonts/noto-sans-cherokee/NotoSansCherokee-Thin.woff2")
      format("woff2"),
    url("../../../fonts/noto-sans-cherokee/NotoSansCherokee-Thin.woff")
      format("woff");
}

@font-face {
  font-family: "Noto Sans Cherokee";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("../../../fonts/noto-sans-cherokee/NotoSansCherokee-ExtraLight.woff2")
      format("woff2"),
    url("../../../fonts/noto-sans-cherokee/NotoSansCherokee-ExtraLight.woff")
      format("woff");
}

@font-face {
  font-family: "Noto Sans Cherokee";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("../../../fonts/noto-sans-cherokee/NotoSansCherokee-Light.woff2")
      format("woff2"),
    url("../../../fonts/noto-sans-cherokee/NotoSansCherokee-Light.woff")
      format("woff");
}

@font-face {
  font-family: "Noto Sans Cherokee";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../../../fonts/noto-sans-cherokee/NotoSansCherokee-Regular.woff2")
      format("woff2"),
    url("../../../fonts/noto-sans-cherokee/NotoSansCherokee-Regular.woff")
      format("woff");
}

@font-face {
  font-family: "Noto Sans Cherokee";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("../../../fonts/noto-sans-cherokee/NotoSansCherokee-Medium.woff2")
      format("woff2"),
    url("../../../fonts/noto-sans-cherokee/NotoSansCherokee-Medium.woff")
      format("woff");
}

@font-face {
  font-family: "Noto Sans Cherokee";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("../../../fonts/noto-sans-cherokee/NotoSansCherokee-SemiBold.woff2")
      format("woff2"),
    url("../../../fonts/noto-sans-cherokee/NotoSansCherokee-SemiBold.woff")
      format("woff");
}

@font-face {
  font-family: "Noto Sans Cherokee";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("../../../fonts/noto-sans-cherokee/NotoSansCherokee-Bold.woff2")
      format("woff2"),
    url("../../../fonts/noto-sans-cherokee/NotoSansCherokee-Bold.woff");
}

@font-face {
  font-family: "Noto Sans Cherokee";
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url("../../../fonts/noto-sans-cherokee/NotoSansCherokee-ExtraBold.woff2")
      format("woff2"),
    url("../../../fonts/noto-sans-cherokee/NotoSansCherokee-ExtraBold.woff");
}

@font-face {
  font-family: "Noto Sans Cherokee";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url("../../../fonts/noto-sans-cherokee/NotoSansCherokee-Black.woff2")
      format("woff2"),
    url("../../../fonts/noto-sans-cherokee/NotoSansCherokee-Black.woff");
}

// Padyakke Expanded One
@font-face {
  font-family: "Padyakke Expanded One";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../../../fonts/padyakke-expanded-one/PadyakkeExpandedOne-Regular.woff2")
      format("woff2"),
    url("../../../fonts/padyakke-expanded-one/PadyakkeExpandedOne-Regular.woff")
      format("woff");
}

// Kumbh Sans
@font-face {
  font-family: "Kumbh Sans";
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url("../../../fonts/kumbh-sans/KumbhSans-Thin.woff2") format("woff2"),
    url("../../../fonts/kumbh-sans/KumbhSans-Thin.woff");
}

@font-face {
  font-family: "Kumbh Sans";
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url("../../../fonts/kumbh-sans/KumbhSans-ExtraLight.woff2")
      format("woff2"),
    url("../../../fonts/kumbh-sans/KumbhSans-ExtraLight.woff");
}

@font-face {
  font-family: "Kumbh Sans";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("../../../fonts/kumbh-sans/KumbhSans-Light.woff2") format("woff2"),
    url("../../../fonts/kumbh-sans/KumbhSans-Light.woff");
}

@font-face {
  font-family: "Kumbh Sans";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../../../fonts/kumbh-sans/KumbhSans-Regular.woff2") format("woff2"),
    url("../../../fonts/kumbh-sans/KumbhSans-Regular.woff");
}

@font-face {
  font-family: "Kumbh Sans";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("../../../fonts/kumbh-sans/KumbhSans-Medium.woff2") format("woff2"),
    url("../../../fonts/kumbh-sans/KumbhSans-Medium.woff");
}

@font-face {
  font-family: "Kumbh Sans";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("../../../fonts/kumbh-sans/KumbhSans-SemiBold.woff2") format("woff2"),
    url("../../../fonts/kumbh-sans/KumbhSans-SemiBold.woff");
}

@font-face {
  font-family: "Kumbh Sans";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("../../../fonts/kumbh-sans/KumbhSans-Bold.woff2") format("woff2"),
    url("../../../fonts/kumbh-sans/KumbhSans-Bold.woff");
}

@font-face {
  font-family: "Kumbh Sans";
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url("../../../fonts/kumbh-sans/KumbhSans-ExtraBold.woff2")
      format("woff2"),
    url("../../../fonts/kumbh-sans/KumbhSans-ExtraBold.woff");
}

@font-face {
  font-family: "Kumbh Sans";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url("../../../fonts/kumbh-sans/KumbhSans-Black.woff2") format("woff2"),
    url("../../../fonts/kumbh-sans/KumbhSans-Black.woff");
}

//lastica

@font-face {
  font-family: "Lastica";
  font-style: normal;
  font-display: swap;
  src: url("../../../fonts/FontsFree-Net-Lastica.ttf") format("ttf");
}
