.iconStyle {
  position: absolute;
  font-size: 20px;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}

.filter {
  flex-direction: row-reverse;
}
