.risk-managment-matrix {
	border-collapse: collapse;
	width: 100%;
    margin-inline: auto;

	caption {
		.dt-skeleton {
			animation: skeleton-loading 1s linear infinite alternate;
			border-radius: 3px;
			display: inline-block;
			&.dt-skeleton-table-caption-badge {
				width: 120px;
				height: 44px;
			}
		}
	}

	tr {
		td {
			border-bottom: 1px solid #ffffff;
			border-right: 1px solid #ffffff;
			vertical-align: middle;
			text-align: center;
			font-weight: 500;

			&:last-child {
				border-right: none;
			}

			.dt-skeleton {
				width: 120px;
				height: 48px;
				margin-left: 5px;
				animation: skeleton-loading 1s linear infinite alternate;
				border-radius: 3px;
				display: inline-block;
			}

			> div {
				display: flex;
				align-items: center;
				justify-content: center;
				flex-direction: column;
			}

			&.dt-matrix-column {
				height: 48px;
				border: 1px solid #ffffff;
				position: relative;

				.else-risks-drop-btn {
					background: transparent;
					border: none;
					padding: 0px;
					font-size: 18px;
					font-weight: bold;
					height: 14px;
					line-height: 1
				}

				&.dt-skeleton {
					animation: skeleton-loading 1s linear infinite alternate;
				}

				> div {
					flex-direction: row;
					position: absolute;
					bottom: 0px;
					left: 4px;
					background: transparent;
					flex-wrap: wrap;
					width: 90%;
					justify-content: flex-start;
				}

				> span{
					position: absolute;
					right: 8px;
					bottom: 0px;
				}

				.dt-matrix-risk-bullet {
					height: 16px;
					border-radius: 100%;
					background-color: #828282;
					border: 2px solid #ffffff;
					margin: 0px 2px 2px 0px;
					flex: 0 0 16px;
					cursor: default;
					&:hover {
						transform: scale(1.1);
					}

					@media (max-width: 768px) {
						&:nth-of-type(1n+3){
							display: none !important;
						}
					}

					@media (max-width: 1024px) {
						&:nth-of-type(1n+4){
							display: none !important;
						}
					}

					@media (max-width: 1280px) {
						&:nth-of-type(1n+8){
							display: none !important;
						}
					}

					@media (max-width: 1920px) {
						&:nth-of-type(1n+17){
							display: none !important;
						}
					}
				}
				
				.dt-matrix-risk-bullet-else{
					font-size: 12px;
					color: #fff;
					height: 12px;
					line-height: 1;
					font-weight: bold;
					margin-left: 2px;
				}
			}

			&.dt-matrix-y-axis {
				height: 47px;
                max-width: 38px;
                width: 38px;
				background-color: rgb(240, 241, 255);
				border: 1px solid #ffffff;
				.dt-matrix-y-axis-value {
					&.dt-skeleton {
						width: 40px;
					}
				}
				.dt-matrix-y-axis-title {
					&.dt-skeleton {
						width: 70%;
					}
				}
			}

			&.dt-matrix-y-axis-label {
				
				background-color: rgb(240, 241, 255);
                border: 1px solid #ffffff;
				font-weight: 500;
                max-width: 38px;
                width: 38px;
			}

			&.dt-matrix-x-axis-label {
				border-radius: 5px 5px 0 0;
				line-height: 30px;
				padding: 10px 0;
				background-color: rgb(240, 241, 255);
				border: 1px solid #fff;
				font-weight: bold;
				> div {
					margin-bottom: 0;
					line-height: 25px;
					flex-direction: row;
				}
			}

			&.dt-matrix-x-axis {
				line-height: 35px;
				background-color: rgb(240, 241, 255);
				border: 1px solid #fff;
				> div {
					margin-bottom: 0;
					flex-direction: column;
					line-height: 25px;
					padding: 10px 0;
					.dt-matrix-x-axis-title {
						margin-bottom: 10px;
						&.dt-skeleton {
							width: 70%;
							display: block;
						}
					}

					.dt-skeleton {
						width: 50px;
						height: 25px;
						margin-left: 5px;
						animation: skeleton-loading 1s linear infinite alternate;
						border-radius: 3px;
						display: inline-block;
					}
				}
			}
		}
	}
}