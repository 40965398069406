html[data-module='gd']{
.keywords-container {
  min-height: 100px;
  border: 1px solid #ced4da;
  padding: 0.375rem 0.75rem;
  border-radius: 0.25rem;
  overflow: auto;
  margin-top: 1rem;
  background-color: #fff; // Adding background color to resemble a textarea
  &:focus {
    outline: none; // Removes the outline on focus to mimic a textarea
  }
}

.tag {
  display: inline-block;
  background-color: #e9ecef;
  border-radius: 4px;
  padding: 1px, 8px, 1px, 8px;
  margin: 2px;
  font-size: 14px;
  background-color: rgba(248, 249, 250, 1);
  cursor: pointer;
  border: 1px solid rgba(206, 212, 218, 1);

  .tag-close {
    margin-left: 5px;
    cursor: pointer;
  }

  &:hover {
    background-color: darken(
      #e9ecef,
      5%
    ); // Darkens the tag background on hover
  }
}
}