.dissco--custom-radial-chart .apexcharts-legend-series:after {
    content: "%";
    font-size: 75%;
  }
  .dissco--custom-radial-chart .apexcharts-legend {
    top: inherit !important;
    left: inherit !important;
    right: 60% !important;
    bottom: 50% !important;
  
    text-transform: capitalize !important;
  }